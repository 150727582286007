<template>
  <div id="ModalPermissionDetails" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Permission Details</h4>
          <button
              class="btn-close"
              type="button"
              aria-label="Close"
              @click="closePermissionDetailsModal"
          ></button>
        </div>
        <div id="ModalBodyPermissionDetails" class="modal-body">
          <div class="col-sm-12 col-xl-12">
            <div class="card">
              <div class="card-header p-0 pb-2">
                <div class="row invo-profile">
                  <div class="col-xl-6">
                    <div class="invo-profile-left">
                      <div class="d-flex">
                        <div class="d-flex-left">
                          <i style="font-size: 40px; color:var(--theme-deafult) !important;" class="ms-4" :class="the_permission?.sub_module?.module?.ico"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h5 class="d-flex-heading">
                            {{ the_permission?.name }}
                          </h5>
                          <p>
                            {{ the_permission?.sub_module?.module?.module_group.name }} >
                            {{ the_permission?.sub_module?.module?.name }} >
                            {{ the_permission?.sub_module.name }}
                            <br/>
                            <span class="digits">
                              <RoleBadge :role="the_permission?.role?.name"/>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="invo-profile-right">
                      <div id="project" class="text-xl-end">
                        <h6 class="fw-bolder text-dark">{{ the_permission?.agency?.title }}</h6>
                        <p class="m-0">
                          {{ the_permission?.description}}
                        </p>
                        <p class="m-0">{{ '' }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0 mt-2">
                <div class="row">
                  <div class="col-md-6">
                    <UsersHasPermission
                        @refresh-permission-details="getPermissionDetailsToModal(drpStore.permission_details.id)"
                        @user-details="(id) => userDetailsRef.getUserDetailsToModal(id)"
                        @delete-permission="(model_id) => deletePermissionFromModel(model_id, 'user')"/>
                  </div>
                  <div class="col-md-6">
                    <AgenciesHasPermission
                        @refresh-permission-details="getPermissionDetailsToModal(drpStore.permission_details.id)"
                        @agency-details="(id) => agencyDetailsRef.getAgencyDetailsToModal(id)"
                        @delete-permission="(model_id) => deletePermissionFromModel(model_id, 'agency')"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closePermissionDetailsModal">
            {{ $translate('common.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <AgencyDetailGm ref="agencyDetailsRef"/>
  <UserDetails ref="userDetailsRef"/>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import {Modal} from 'bootstrap';
import {BlockContainer, ConfirmWithSwal, ToastMessage, UnBlocker} from '@/core/services/general.service';
import {$translate} from '@/core/services/translator.service';
import AgencyDetailGm from '@/components/Agency/Details/AgencyDetailGm.vue';
import RoleBadge from '@/components/users/mangement/RoleBadge.vue';
import UsersHasPermission
  from "@/components/configuration/DefaultRolePermission/PermissionDetail/includes/UsersHasPermission.vue";
import AgenciesHasPermission
  from "@/components/configuration/DefaultRolePermission/PermissionDetail/includes/AgenciesHasPermission.vue";
import {useDefaultRolePermissionStore} from "@/stores/SystemConfiguration/defaultRolePermissionStore";
import UserDetails from "@/components/users/mangement/UserDetails.vue";


const emit = defineEmits(['close-modal', 'refresh-users-datagrid']);

const agencyDetailsRef = ref(null);
const userDetailsRef = ref(null);

const deletePermissionFromModel = async (model_id, model_type) => {

  let confirmResult = await ConfirmWithSwal('warning', 'The permission will be deleted and this action cannot be undone. Should we continue?', 'Confirm Delete Permission');

  if (!confirmResult)
    return 0;

  const loader = BlockContainer('ModalBodyPermissionDetails');
  await drpStore.deletePermission(the_permission.value.id, model_id, model_type);

  UnBlocker(loader);
  await getPermissionDetailsToModal(the_permission.value.id)
}


const drpStore = useDefaultRolePermissionStore();
const the_permission = computed(() => drpStore.permission_details);

const activeTab = ref('user');

const getPermissionDetailsToModal = async (permission_id) => {
  openPermissionDetailsModal();

  activeTab.value = 'user';

  const loader = BlockContainer('ModalBodyPermissionDetails');
  await drpStore.getPermissionDetails(permission_id);

  UnBlocker(loader);
};

const PermissionDetailsRefresh = (id) => {
  getPermissionDetailsToModal(id);
};

const modalPermissionDetails = ref(null);

onMounted(() => {
  modalPermissionDetails.value = new Modal(document.getElementById('ModalPermissionDetails'), {
    backdrop: false,
    keyboard: false,
  });
});

const openPermissionDetailsModal = () => {
  modalPermissionDetails.value.show();
};

const closePermissionDetailsModal = () => {
  modalPermissionDetails.value.hide();
};

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

defineExpose({
  getPermissionDetailsToModal,
});
</script>

<style scoped>
ul.nav-tabs li.nav-item {
  list-style: none;
}
</style>
