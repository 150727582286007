import { ToastMessage } from "@/core/services/general.service";

export default class ErrorService {
    apiClientError(error, showMessage = true, showValidationErrors = false, validationErrorDisplayType = 'bag') {
        console.error("Service Error: ", error);
        if (showMessage && (!showValidationErrors || error.response?.status !== 422)) {
            const errorMessage = error.response?.data?.message || error.message;
            ToastMessage('error', errorMessage, "Error!");
        }

        if (showValidationErrors && error.response && error.response.status === 422) {
            const errors = error.response.data.errors;

            if (validationErrorDisplayType === 'bag') {
                let message = '<ul>';
                for (let key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        message += `<li>${errors[key][0]}</li>`;
                    }
                }
                message += '</ul>';
                ToastMessage('error', message, "Validation Error");
            } else if (validationErrorDisplayType === 'separate') {
                for (let key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        const separateMessage = `${key}: "${errors[key][0]}"`;
                        ToastMessage('error', separateMessage, "Validation Error");
                    }
                }
            }
        }
    }
};

export const errorService = new ErrorService();
