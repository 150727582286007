<template>
  <div>
    <DxDataGrid
      :show-borders="true"
      :data-source="agencyStore.selected_agency.permissions"
      :column-auto-width="true"
      :show-row-lines="true"
      theme="compact"
      :show-column-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="false"
      :allow-column-resizing="true"
      :column-resizing-mode="'widget'"
      :width="'100%'"
      :height="700"
      @toolbarPreparing="dxOnToolbarPreparing"
      @contextMenuPreparing="dxOnContextMenuPreparing"
      @exporting="(e) => dxOnExcelExporting(e, 'Agency Permission', 'Agency Permission')"
    >
      <DxExport :enabled="true" :allow-export-selected-data="false" />
      <DxPaging :page-size="500" />
      <DxPager
        :visible="true"
        :allowed-page-sizes="dxPageSizes"
        :display-mode="'compact'"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />

      <DxColumn data-field="permission.sub_module.module.module_group.name" caption="Module Group Name" />

      <DxColumn data-field="permission.sub_module.module.name" caption="Module" cell-template="iconPreviewTemplate" />

      <template #iconPreviewTemplate="{ data: rowInfo }">
        <div class="flex justify-center">
          <i class="sidebar-module-icon" :class="rowInfo.data.permission.sub_module.module.ico"></i>
          {{ rowInfo.data.permission.sub_module.module.name }}
        </div>
      </template>

      <DxColumn data-field="permission.sub_module.name" caption="Sub Module" />
      <DxColumn data-field="permission.name" caption="Permission" />

      <DxColumn data-field="permission.description" caption="Permission Description" />

      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxGroupPanel visible="true" />
      <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
    </DxDataGrid>
  </div>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxSelection,
  DxGroupPanel,
  DxRequiredRule,
  DxPaging,
  DxPager,
  DxExport,
} from "devextreme-vue/data-grid";
import {
  dxOnExcelExporting,
  dxOnContextMenuPreparing,
  dxPageSizes,
  dxOnToolbarPreparing,
  dxDateEditorOptions,
  dxOnBeforeSendFunction,
  dxOnAjaxErrorHandler,
} from "@/core/services/devextreme.datagrid.service";
import { useAgencyStore } from "@/stores/agencyStore";

const agencyStore = useAgencyStore();
</script>

<style lang="scss" scoped></style>
