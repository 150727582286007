<template>
  <router-view />
</template>

<script setup></script>

<style>
#toast-container {
  top: 11% !important;
  /* Üstten %10 aşağıda */
}
</style>
