import { onBeforeUnmount, onMounted } from 'vue';

export const vClickOutside = {
    beforeMount(el, binding) {
        el.clickOutsideEvent = (event) => {
            // Eğer tıklama elementin dışında ise, binding.value() çağrılır
            if (!(el == event.target || el.contains(event.target))) {
                binding.value();
            }
        };
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
    },
};
