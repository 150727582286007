<template>
  <div class="container-fluid">
    <Breadcrumbs main="Modül Sıralama"/>
    <div class="row starter-main">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>Modul Sıralama Yönetimi</h5>
            <div class="card-header-right">
              <ul class="list-unstyled card-option">
                <li><i class="fa fa-spin fa-cog"></i></li>
                <li><i class="view-html fa fa-code"></i></li>
                <li><i class="icofont icofont-maximize full-card"></i></li>
                <li><i class="icofont icofont-minus minimize-card"></i></li>
                <li><i class="icofont icofont-refresh reload-card"></i></li>
                <li><i class="icofont icofont-error close-card"></i></li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <ul style="list-style-type: none;" class="nav nav-tabs border-tab" id="top-tab" role="tablist">
              <li class="nav-item">
                <router-link :class="setActive('ModuleGroups')" class="nav-link" id="configuration-module-groups-tab"
                             to="ModuleGroups"><i
                    class="icofont icofont-layers"></i>Modül Grupları
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :class="setActive('Modules')" class="nav-link" id="configuration-modules-tab"
                             to="Modules"><i
                    class="icofont icofont-cubes"></i>Modüller
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :class="setActive('SubModules')" class="nav-link" id="configuration-sub-modules-tab"
                             to="SubModules"><i
                    class="icofont icofont-sub-listing"></i>Alt Modüller
                </router-link>
              </li>
            </ul>
            <div class="tab-content" id="top-tabContent">
              <div class="tab-pane fade show active" id="configuration-sub-all-permissions" role="tabpanel"
                   aria-labelledby="configuration-sub-all-permissions-tab">
                <Suspense>
                  <template #default>
                    <RouterView/>
                  </template>
                  <template #fallback>
                    <div>Loading...</div>
                  </template>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useModuleSortingStore } from "@/stores/SystemConfiguration/moduleSortingStore";

const route = useRoute();
const moduleSortingStore = useModuleSortingStore();


const setActive = (page) => {
  const currentPath = route.path.toLowerCase().split('/').pop();
  return currentPath === page.toLowerCase() ? 'active' : '';
};

</script>
