<template>
  <DxDataGrid
      :show-borders="true"
      :data-source="dataSource"
      :show-row-lines="true"
      :show-column-lines="true"
      :row-alternation-enabled="true"
      ref="dataGridRef"
      :remote-operations="false"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :column-resizing-mode="'widget'"
      :width="'100%'"
      :height="700"
      @toolbarPreparing="dxOnToolbarPreparing"
      @contextMenuPreparing="dxOnContextMenuPreparing"
      @exporting="(e) => dxOnExcelExporting(e, 'Tüm İzinler', 'Tüm İzinler')"
  >
    <DxColumnChooser :enabled="true"/>
    <DxExport :enabled="true" :allow-export-selected-data="false"/>
    <DxPaging :page-size="500"/>
    <DxPager
        :visible="true"
        :allowed-page-sizes="dxPageSizes"
        :display-mode="'compact'"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
    />

    <DxColumn data-field="id"/>
    <DxColumn data-field="module_group_name" caption="Modül Grubu">
      <DxRequiredRule/>
    </DxColumn>
    <DxColumn
        :group-index="0"

        data-field="module_name" caption="Modül Adı" cell-template="moduleNameAndIcoTemplate"/>
    <template #moduleNameAndIcoTemplate="{ data: rowInfo }">
      <div class="flex justify-center">
        <i class="sidebar-module-icon" :class="rowInfo.data.ico"></i> {{ rowInfo.data.module_name }}
      </div>
    </template>

    <DxColumn data-field="sub_module_name" :allow-editing="false" caption="Alt Modül Adı"></DxColumn>
    <DxColumn data-field="name" :allow-editing="false" caption="İzin"></DxColumn>
    <DxColumn data-field="guard_name" :allow-editing="false" caption="Guard Name"></DxColumn>
    <DxColumn data-field="assignable_by_agent" :allow-editing="false" caption="Acente Trfn. Atanabilir"
              cell-template="onlyOneYesOrNo" :visible="false"></DxColumn>
    <template #onlyOneYesOrNo="{ data: rowInfo }">
      <div class="flex justify-center">
        <b :class="rowInfo.data.assignable_by_agent ? 'text-success' : 'text-danger'"
        >{{ rowInfo.data.assignable_by_agent ? 'Evet' : 'Hayır' }}</b>
      </div>
    </template>

    <DxColumn data-field="description" caption="Açıklama">
      <DxRequiredRule/>
    </DxColumn>


    <DxColumn
        data-field="created_at_format"
        data-type="date"
        caption="Kayıt Tarihi"
        :allow-editing="false"
        sort-order="desc"
        :editor-options="dxDateEditorOptions"
    >
    </DxColumn>


    <DxFilterRow :visible="true"/>
    <DxHeaderFilter :visible="true"/>

    <DxEditing
        :allow-adding="false"
        :allow-updating="false"
        :allow-deleting="true"
        mode="row"
    />
    <DxColumn type="buttons">
      <DxButton name="delete"/>
      <DxButton icon="edit" @click="(e) => editModule(e)"/>
    </DxColumn>


    <DxSearchPanel
        :visible="true"
        :highlight-case-sensitive="true"
    />
    <DxGroupPanel :visible="true"/>

    <DxSummary>
      <DxGroupItem column="key" summary-type="count" display-format="{0} Permission(s)"/>
    </DxSummary>

    <DxToolbar>
      <DxItem location="after">
        <button @click="createModule()" class="btn btn-primary btn-xs">
          <i class="fa fa-plus"></i>
        </button>
      </DxItem>
      <DxItem name="columnChooserButton"/>
      <DxItem name="exportButton"/>
      <DxItem name="groupPanel"/>
    </DxToolbar>
  </DxDataGrid>


  <ModalPermissionForm
      @close-modal="closeModal()"
      @refresh-datagrid="dataGridRef.instance.refresh()"
      :title="formTitle"
  />
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxRequiredRule,
  DxLookup,
  DxPaging,
  DxPager,
  DxExport,
  DxToolbar,
  DxItem,
  DxColumnChooser,
  DxButton, DxGroupPanel, DxGroupItem, DxSummary,
} from 'devextreme-vue/data-grid';
import {createStore} from 'devextreme-aspnet-data-nojquery';
import {systemConfigurationService} from "@/core/api/system-configuration.api";
import {
  dxOnExcelExporting,
  dxOnContextMenuPreparing,
  dxPageSizes,
  dxOnToolbarPreparing,
  dxDateEditorOptions,
  dxOnBeforeSendFunction, dxOnAjaxErrorHandler
} from "@/core/services/devextreme.datagrid.service";
import {onMounted, reactive, ref} from "vue";
import 'bootstrap/dist/js/bootstrap';
import ModalPermissionForm from "@/pages/configuration/modules/includes/modals/ModalPermissionForm.vue";
import {Modal} from 'bootstrap';
import {useModuleStore} from "@/stores/SystemConfiguration/moduleStore";
import {usePermissionStore} from "@/stores/SystemConfiguration/permissionStore";


const moduleStore = useModuleStore();

const permissionStore = usePermissionStore();


const dataGridRef = ref(DxDataGrid);

const url = systemConfigurationService.allPermissionResource();

const dataSource = createStore({
  key: 'id',
  loadUrl: `${url}`,
  insertUrl: `${url}`,
  updateUrl: `${url}/update`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: dxOnBeforeSendFunction,
  onAjaxError: dxOnAjaxErrorHandler
});

const modalModuleForm = ref(null);

onMounted(() => {
  modalModuleForm.value = new Modal(document.getElementById('ModalPermissionForm'), {
    backdrop: 'static',
    keyboard: false,
    focus: true,
    show: false
  });
});

const editMode = ref(false);
const formTitle = ref('İzin Oluştur');
const moduleDetails = ref(null);

const createModule = () => {
  moduleDetails.value = null;
  permissionStore.editMode = false;
  permissionStore.editPermissionDetails = null;
  formTitle.value = 'İzin Oluştur';
  openModal();
}

const editModule = (e) => {
  // console.log("here is the e.row.data here", e.row.data);
  permissionStore.editPermissionDetails = e.row.data;
  permissionStore.editMode = true;
  formTitle.value = 'İzin Düzenle';
  openModal();
}

const openModal = () => {
  modalModuleForm.value.show();
};

const closeModal = () => {
  modalModuleForm.value.hide();
};
</script>