<template>
  <div id="ModalPermissionForm" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ title }}</h4>
          <button class="btn-close" type="button" aria-label="Close"
                  @click="emit('close-modal')"></button>
        </div>
        <div class="modal-body">
          <Form ref="veeForm" as="form" id="formPermission" @submit="handleSubmit()"
                v-slot="{ values, meta, errors, errorBag, isSubmitting, isValidating, submitCount }">
            <div style="width: 100%; zoom:1.5; display: none;" class="badge badge-primary">
              <pre> values: {{ values }}</pre>
              <pre>errors: {{ errors }}</pre>
              <pre>formData: {{ formData }}</pre>
            </div>

            <div class="row g-3 mb-2">
              <div class="col-md-6">
                <label class="form-label">Alt Modül Adı:</label>
                <Field v-model="formData.sub_module_id" as="select" name="sub_module_id" class="form-control"
                       rules="required" :class="{'is-invalid': errors.sub_module_id}">
                  <option value="">Seçiniz</option>
                  <option v-for="sm in permissionStore.sub_modules" :key="sm.id" :value="sm.id"
                  >{{ sm.name }} ({{ sm.module?.name }}/{{ sm.module?.module_group?.name }})
                  </option>
                </Field>
                <ErrorMessage as="div" class="invalid-feedback" name="sub_module_id"/>
              </div>
              <div class="col-md-6">
                <label class="form-label" for="validationCustom02">İzin Adı:</label>
                <Field v-model="formData.name" name="name" type="text" class="form-control"
                       placeholder="Enter a name" rules="required" :class="{'is-invalid': errors.name}"/>
                <ErrorMessage as="div" class="invalid-feedback" name="name"/>
              </div>
            </div>

            <div class="row g-3 mb-2">
              <div class="col-md-6 mb-3">
                <label class="form-label text-primary" for="routePrimary">Guard Name:</label>
                <Field v-model="formData.guard_name" name="guard_name" type="text" class="form-control"
                       placeholder="Enter route" rules="required"
                       :class="{'is-invalid': errors.guard_name}"/>
                <ErrorMessage as="div" class="invalid-feedback" name="guard_name"/>
              </div>

              <div class="col-md-6 mb-3">
                <label class="form-label text-primary" for="routePrimary">Acente Admin Tarafından Atanabilir:</label>
                <Field v-model="formData.assignable_by_agent" as="select" name="assignable_by_agent"
                       class="form-control" rules="required" :class="{'is-invalid': errors.assignable_by_agent}">
                  <option value="true">Evet</option>
                  <option value="false">Hayır</option>
                </Field>
                <ErrorMessage as="div" class="invalid-feedback" name="assignable_by_agent"/>
              </div>
            </div>

            <div class="row g-3 mb-2">
              <div class="col-md-12 mb-3">
                <label class="form-label" for="description">Açıklama</label>
                <Field v-model="formData.description" name="description" as="textarea" class="form-control"
                       placeholder="Zorunlu Alan"
                       rules="required" :class="{'is-invalid': errors.description}"/>
                <ErrorMessage as="div" class="invalid-feedback" name="description"/>
              </div>
            </div>
          </Form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="emit('close-modal')">Close</button>
          <button type="submit" form="formPermission" class="btn btn-primary">Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Field, Form, ErrorMessage} from 'vee-validate';
import {reactive, ref, watch} from "vue";
import {usePermissionStore} from "@/stores/SystemConfiguration/permissionStore";
import {ToastMessage} from "@/core/services/general.service";

const veeForm = ref();

const permissionStore = usePermissionStore();
permissionStore.getSubModules();


const formData = reactive({
  sub_module_id: null,
  name: null,
  guard_name: null,
  description: null,
  assignable_by_agent: "true",
});


const emit = defineEmits([
  'close-modal',
  'refresh-datagrid'
]);

const props = defineProps({
  title: {
    type: String,
    required: true
  },
});


const handleSubmit = async () => {
  try {
    let response = null;
    if (permissionStore.editMode)
      response = await permissionStore.editPermission(formData);
    else
      response = await permissionStore.createPermission(formData);


    if (response?.status === 200) {
      ToastMessage('success', response.data.message, 'Başarılı!');

      clearData();

      emit('close-modal');
      emit('refresh-datagrid');
    }

  } catch (e) {
    throw e;
  }
}

const clearData = () => {
  Object.assign(formData, {
    sub_module_id: null,
    name: null,
    guard_name: null,
    description: null
  });
}


watch(() => permissionStore.getEditSubModuleData, (newData) => {
  if (newData)
    Object.assign(formData, newData);
  else
    clearData();

}, {immediate: true});

</script>
