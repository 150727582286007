<template>
  <DxDataGrid
      :show-borders="true"
      :data-source="dataSource"
      :show-row-lines="true"
      :show-column-lines="true"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      :remote-operations="{
        groupPaging: true,
        paging: true,
        filtering: true,
        sorting: true,
        grouping: false,
        summary: true,
      }"
      :allow-column-resizing="true"
      :column-resizing-mode="'widget'"
      :width="'100%'"
      :height="700"
      @toolbarPreparing="dxOnToolbarPreparing"
      @contextMenuPreparing="dxOnContextMenuPreparing"
      @exporting="(e) => dxOnExcelExporting(e, 'Yetkiler', 'Yetkiler')"
  >
    <DxExport
        :enabled="true"
        :allow-export-selected-data="false"
    />
    <DxPaging :page-size="500"/>
    <DxPager
        :visible="true"
        :allowed-page-sizes="dxPageSizes"
        :display-mode="'compact'"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
    />

    <DxColumn data-field="name" caption="Yetki Adı">
      <DxRequiredRule/>
    </DxColumn>
    <DxColumn data-field="display_name" caption="Yetki Görünüm Adı">
      <DxRequiredRule/>
    </DxColumn>
    <DxColumn cell-template="roleGroupTemplate" data-field="role_group" caption="Yetki Grubu">
      <DxRequiredRule/>
      <DxLookup :data-source="roleStore.roleGroups"/>
    </DxColumn>
    <template #roleGroupTemplate="{data:rowInfo}">
      <RoleGroupStyle :role-group="rowInfo.data.role_group"/>
    </template>
    <DxColumn data-field="description" caption="Açıklama">
      <DxRequiredRule/>
    </DxColumn>


    <DxColumn data-field="created_at_format"
              data-type="date"
              caption="Kayıt Tarihi"
              :allow-editing="false"
              sort-order="desc"
              :editor-options="dxDateEditorOptions">
    </DxColumn>

    <DxFilterRow :visible="true"/>
    <DxHeaderFilter :visible="true"/>

    <DxEditing
        :allow-adding="true"
        :allow-updating="true"
        :allow-deleting="true"
        mode="row"
    />
    <DxSearchPanel
        :visible="true"
        :highlight-case-sensitive="true"
    />
  </DxDataGrid>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxRequiredRule,
  DxPaging,
  DxPager,
  DxExport,
  DxLookup,
} from 'devextreme-vue/data-grid';
import {createStore} from 'devextreme-aspnet-data-nojquery';
import {systemConfigurationService} from "@/core/api/system-configuration.api";
import {
  dxOnExcelExporting,
  dxOnContextMenuPreparing,
  dxPageSizes,
  dxOnToolbarPreparing,
  dxDateEditorOptions,
  dxOnBeforeSendFunction,
  dxOnAjaxErrorHandler
} from "@/core/services/devextreme.datagrid.service";
import {onMounted} from "vue";
import {useRoleStore} from "@/stores/SystemConfiguration/roleStore";
import RoleGroupStyle from "@/components/configuration/modules/roles/RoleGroupStyle.vue";

const roleStore = useRoleStore();

const yesOrNo = [
  {value: true, display_name: 'Evet'},
  {value: false, display_name: 'Hayır'}
];

const url = systemConfigurationService.rolesResource();

const dataSource = createStore({
  key: 'id',
  loadUrl: `${url}`,
  insertUrl: `${url}`,
  updateUrl: `${url}/update`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: dxOnBeforeSendFunction,
  onAjaxError: dxOnAjaxErrorHandler
});


onMounted(() => {
  roleStore.getRoleGroups();
});
</script>
