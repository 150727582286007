<template>
  <div id="ModalUserForm" class="modal fade" tabindex="0" style="z-index: 1051">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ title }}</h4>
          <button
            class="btn-close"
            type="button"
            aria-label="Close"
            @click="closeModal()"
          ></button>
        </div>
        <div id="ModalBodyUserForm" class="modal-body">
          <Form
            ref="veeForm"
            as="form"
            id="formUser"
            @submit="handleSubmit()"
            v-slot="{ errors }"
          >
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="form-label">
                  {{ $translate('users.management.user_form.name_surname') }}
                </label>
                <Field
                  v-model="user_form_data.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :placeholder="
                    $translate(
                      'users.management.user_form.name_surname_placeholder'
                    )
                  "
                  rules="required"
                  :class="{ 'is-invalid': errors.name }"
                />
                <ErrorMessage as="div" class="invalid-feedback" name="name" />
              </div>
              <div class="col-md-6">
                <label class="form-label" for="validationCustom02">
                  {{ $translate('users.management.user_form.email') }}
                </label>
                <Field
                  v-model="user_form_data.email"
                  name="email"
                  type="text"
                  class="form-control"
                  :placeholder="
                    $translate('users.management.user_form.email_placeholder')
                  "
                  rules="required|email"
                  :class="{ 'is-invalid': errors.email }"
                />
                <ErrorMessage as="div" class="invalid-feedback" name="email" />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-4">
                <label class="form-label" for="validationCustom02">
                  {{ $translate('users.management.user_form.password') }}
                </label>
                <Field
                  v-model="user_form_data.password"
                  name="password"
                  type="text"
                  class="form-control"
                  :disabled="!editMode"
                  :placeholder="
                    $translate(
                      'users.management.user_form.password_placeholder'
                    )
                  "
                  :class="{ 'is-invalid': errors.password }"
                />
                <small v-if="!editMode" class="text-info">
                  {{
                    $translate(
                      'users.management.user_form.password_description1'
                    )
                  }}
                </small>
                <small v-else class="text-info">
                  {{
                    $translate(
                      'users.management.user_form.password_description2'
                    )
                  }}
                </small>
                <ErrorMessage
                  as="div"
                  class="invalid-feedback"
                  name="password"
                />
              </div>

              <div class="col-md-4">
                <label class="form-label">
                  {{ $translate('users.management.user_form.country_code') }}
                </label>
                <ValidatedSelect2CountryCodes
                  v-model="user_form_data.country_code"
                  @select="countryCodeChangeEvent($event)"
                  name="country_code"
                  :options="countries"
                  rules="required"
                  :drop-down-parent="'#ModalUserForm'"
                  :class="{ 'is-invalid': errors.country_code }"
                />
              </div>
              <div class="col-md-4">
                <label class="form-label">
                  {{ $translate('users.management.user_form.phone') }}
                </label>
                <Field
                  v-model="user_form_data.phone"
                  name="phone"
                  type="text"
                  class="form-control"
                  :placeholder="
                    $translate('users.management.user_form.phone_placeholder')
                  "
                  rules="required|numeric"
                  :class="{ 'is-invalid': errors.phone }"
                />
                <ErrorMessage as="div" class="invalid-feedback" name="phone" />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-6">
                <label class="form-label" for="validationCustom02">
                  {{
                    $translate('users.management.user_form.dependent_agency')
                  }}
                </label>
                <ValidatedSelect2
                  :drop-down-parent="'#ModalUserForm'"
                  v-model="user_form_data.agency_id"
                  name="agency_id"
                  :options="agencies"
                  text-key="title"
                  rules=""
                />
              </div>

              <div class="col-md-6">
                <label class="form-label" for="validationCustom02">
                  {{ $translate('users.management.user_form.role') }}
                </label>
                <ValidatedSelect2
                  :empty-option="true"
                  :drop-down-parent="'#ModalUserForm'"
                  v-model="user_form_data.role_id"
                  name="role_id"
                  :options="userStore.available_roles"
                  text-key="name"
                  :placeholder="
                    $translate('users.management.user_form.role_placeholder')
                  "
                  rules="required"
                />
              </div>
            </div>
            <!-- <pre>{{ user_form_data }}</pre>-->
          </Form>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal()">
            {{ $translate('common.close') }}
          </button>
          <button type="submit" form="formUser" class="btn btn-primary">
            {{ $translate('common.save_changes') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import { computed, onMounted, reactive, ref } from 'vue';
  import {
    BlockContainer,
    ToastMessage,
    UnBlocker,
  } from '@/core/services/general.service';
  import ValidatedSelect2 from '@/components/ValidatedSelect2.vue';
  import { useAgencyStore } from '@/stores/agencyStore';
  import { useUserStore } from '@/stores/userStore';
  import ValidatedSelect2CountryCodes from '@/components/ValidatedSelect2CountryCodes.vue';
  import { errorService } from '@/core/services/error.service';
  import { $translate } from '@/core/services/translator.service';
  import { Modal } from 'bootstrap';

  const agencyStore = useAgencyStore();
  const userStore = useUserStore();

  const countries = ref([]);
  const agencies = ref([]);
  const editMode = ref(false);
  const title = computed(() =>
    editMode.value
      ? $translate('users.management.user_form.edit_title')
      : $translate('users.management.user_form.create_title')
  );

  const modalUserForm = ref();

  const countryCodeChangeEvent = ({ id, text }) => {
    user_form_data.country_code = id;
  };

  onMounted(async () => {
    modalUserForm.value = new Modal(document.getElementById('ModalUserForm'), {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
    });

    countries.value = await agencyStore.getCountries();
    agencies.value = await agencyStore.getAgencies();
  });

  const veeForm = ref();

  const user_form_data = reactive({
    id: null,
    name: null,
    email: null,
    password: null,
    country_code: null,
    phone: null,
    agency_id: null,
    role_id: null,
  });

  const emit = defineEmits(['refresh-datagrid']);

  const handleSubmit = async () => {
    const loader = BlockContainer('ModalBodyUserForm');

    try {

      let response = null;

      if (editMode.value) {
        response = await userStore.updateUser(user_form_data);
      } else {
        response = await userStore.createUser(user_form_data);
      }

      ToastMessage(
        'success',
        response.data.message,
        $translate('common.successfully')
      );
      emit('refresh-datagrid');
      clearData();
      closeModal();
    } catch (error) {
      errorService.apiClientError(error, true, true);
    } finally {
      UnBlocker(loader);
    }
  };

  const clearData = () => {
    Object.assign(user_form_data, {
      id: null,
      name: null,
      email: null,
      password: null,
      country_code: null,
      phone: null,
      agency_id: null,
      role_id: null,
    });
  };

  const setEditData = (editData) => {
    user_form_data.id = editData.id;
    user_form_data.name = editData.name;
    user_form_data.email = editData.email;
    user_form_data.country_code = editData.customized?.phone_code_country_id;
    user_form_data.phone = editData.customized?.phone;
    user_form_data.agency_id = editData.agency_id;
    user_form_data.role_id = editData.role_id;
  };

  const openModal = (editModeParam = false, user = null) => {
    // console.log(e.row.data)
    clearData();

    if (editModeParam) setEditData(user);

    editMode.value = editModeParam;
    modalUserForm.value.show();
  };

  const closeModal = () => {
    modalUserForm.value.hide();
  };

  defineExpose({
    openModal,
  });
</script>

<style scoped>
  .modal-backdrop {
    z-index: 1050 !important;
  }
</style>
