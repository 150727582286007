<template>
  <svg class="template">

    <text
        class="template-name"
        x="50%"
        y="20%"
        @click="showEmployeeInfo">
      {{ agency.title }}
    </text>

    <text
        class="price-area"
        x="50%"
        y="30%"
        text-anchor="middle"
    >
      <tspan v-for="(line, index) in packageNameLines" :key="index" x="50%" dy="1.2em">{{ line }}</tspan>
      <!--      {{ defaultMarkupRates }}-->
    </text>


    <text
        class="price-area"
        x="50%"
        y="70%"
        text-anchor="middle"
    >
      Markup:
    </text>

    <text
        class="price-area"
        x="50%"
        y="75%"
        text-anchor="middle"
    >
      <tspan v-for="(line, index) in markupStringLines" :key="index" x="50%" dy="1.2em">{{ line }}</tspan>

    </text>
  </svg>
</template>

<script setup>
import {computed, defineProps, onMounted} from 'vue';
import {useAgencyStore} from "@/stores/agencyStore";
import PriceView from "@/components/PriceView.vue";
import {number_format, currencyExchange, priceFormatter} from "@/core/services/general.service";
import {ucfirst} from "@/core/services/string.service";

const agencyStore = useAgencyStore();

const props = defineProps({
  agency: {
    type: Object,
    default: () => ({})
  },
  showInfo: {
    type: Function,
    default: () => {
    }
  }
});


const defaultMarkupRates = computed(() => {

  const groupedMarkups = props.agency.markups.reduce((acc, markup) => {
    const categoryId = markup.category_id;
    if (!acc[categoryId]) {
      acc[categoryId] = [];
    }
    acc[categoryId].push(markup);
    return acc;
  }, {});

  let finalRates = "";

  Object.keys(groupedMarkups).forEach(category_id => {

    let rate = "";

    const markups = groupedMarkups[category_id];

    let multipleMarkups = markups.length > 1;


    markups.forEach((markup) => {
      if (markup.package_id === agencyStore.diagram.displayed_markup_package_id) {

        let formattedRate = "";

        if (multipleMarkups) {


          if (markup.category === 'percent') {
            // TODO: TEST HERE WHEN "percent" CATEGORY READY!
            rate += "%" + markup.value + " ";
          } else {
            // #per-transaction
            formattedRate = "(" + ucfirst(markup.package_type.name) + ")" + ": " + priceFormatter(currencyExchange(markup.value, markup.currency).converted_price);
            rate += formattedRate + " ";
          }


        } else {

          let markupWithCategory = "";
          if (markup.category.name === 'percent') {
            markupWithCategory = "%" + markup.value;
          } else {
            //TODO: TEST HERE WHEN "per-transaction" SINGLE MARKUP READY!
            markupWithCategory = priceFormatter(currencyExchange(markup.value, markup.currency).converted_price);
          }

          rate = ucfirst(markup.package_type.package.name) + ": " + markupWithCategory;
        }

        if (!markup.has_permission)
          rate = ucfirst(markup.package_type.package.name) + ': ⛔️'

      }
    })
    finalRates += rate.trim() + " ";
  });


  return finalRates.trim();
})


const packageName = computed(() => defaultMarkupRates.value);
const maxCharsPerLine = 30;
const packageNameLines = computed(() => {
  const lines = [];
  let currentLine = '';
  packageName.value.split(' ').forEach(word => {
    if ((currentLine + word).length > maxCharsPerLine) {
      lines.push(currentLine.trim());
      currentLine = '';
    }
    currentLine += `${word} `;
  });
  if (currentLine) {
    lines.push(currentLine.trim());
  }
  return lines;
});

const agencyMarkup = computed(() => {
  if (props.agency?.markups && props.agency.markups.length > 0) {

    const groupedMarkups = props.agency.markups.reduce((acc, markup) => {
      const categoryId = markup.category_id;
      if (!acc[categoryId]) {
        acc[categoryId] = [];
      }
      acc[categoryId].push(markup);
      return acc;
    }, {});


    let finalPrice = "";

    Object.keys(groupedMarkups).forEach(category_id => {
      const markups = groupedMarkups[category_id];

      let price = "";
      let multipleMarkups = markups.length > 1;

      markups.forEach((markup) => {
        if (markup.package_id === agencyStore.diagram.displayed_markup_package_id) {
          let formattedPrice = "";
          let pricePrefix = "";
          if (multipleMarkups) {
            pricePrefix = `(${ucfirst(markup.package_type.name)}): `
          }
          formattedPrice = priceFormatter(markup.calculated_markup);
          price += pricePrefix + formattedPrice + " ";

          if (!markup.has_permission)
            price = '⛔️'
        }
      });

      finalPrice += price.trim() + " ";
    });

    return finalPrice.trim();
  }

  return "";
});

const markupString = computed(() => agencyMarkup.value);
const markupStringLines = computed(() => {
  const lines = [];
  let currentLine = '';
  markupString.value.split(' ').forEach(word => {
    if ((currentLine + word).length > maxCharsPerLine) {
      lines.push(currentLine.trim());
      currentLine = '';
    }
    currentLine += `${word} `;
  });
  if (currentLine) {
    lines.push(currentLine.trim());
  }
  return lines;
});


function showEmployeeInfo() {
  props.showInfo(props.agency);
}
</script>


<style>
#diagram .template .template-name {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

#diagram .template .template-title {
  font-style: italic;
}

#diagram .template .template-button {
  cursor: pointer;
  font-size: 8pt;
  fill: navy;
}

#diagram .template .template-button:hover {
  text-decoration: underline;
}

.price-area {
  font-weight: bold;
  font-size: 1rem;
  color: var(--bs-primary) !important;
}
</style>
