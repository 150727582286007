<template>
  <DxDataGrid
      :show-borders="true"
      :data-source="dataSource"
      :column-auto-width="true"
      :show-row-lines="true"
      :show-column-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="{
        groupPaging: true,
        paging: true,
        filtering: true,
        sorting: true,
        grouping: true,
        summary: true,
      }"
      :allow-column-resizing="true"
      :column-resizing-mode="'widget'"
      :width="'100%'"
      :height="700"
      @toolbarPreparing="dxOnToolbarPreparing"
      @contextMenuPreparing="dxOnContextMenuPreparing"
      @exporting="(e) => dxOnExcelExporting(e, 'Modül Grupları', 'Modül Grupları')"
  >
    <DxExport
        :enabled="true"
        :allow-export-selected-data="false"
    />
    <DxPaging :page-size="500"/>
    <DxPager
        :visible="true"
        :allowed-page-sizes="dxPageSizes"
        :display-mode="'compact'"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
    />

    <DxColumn data-field="name" caption="Grup Adı">
      <DxRequiredRule/>
    </DxColumn>
    <DxColumn data-field="slug" :allow-editing="false" caption="Slug"></DxColumn>
    <DxColumn data-field="description" caption="Açıklama">
      <DxRequiredRule/>
    </DxColumn>
    <DxColumn data-field="created_at_format"
              data-type="date"
              caption="Kayıt Tarihi"
              :allow-editing="false"
              sort-order="desc"
              :editor-options="dxDateEditorOptions">
    </DxColumn>

    <DxFilterRow :visible="true"/>
    <DxHeaderFilter :visible="true"/>

    <DxEditing
        :allow-adding="true"
        :allow-updating="true"
        :allow-deleting="true"
        mode="row"
    />
    <DxSearchPanel
        :visible="true"
        :highlight-case-sensitive="true"
    />
  </DxDataGrid>
</template>

<script setup>

import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxSelection,
  DxRequiredRule,
  DxPaging,
  DxPager,
  DxExport,
} from 'devextreme-vue/data-grid';
import {createStore} from 'devextreme-aspnet-data-nojquery';
import {systemConfigurationService} from "@/core/api/system-configuration.api";
import {
  dxOnExcelExporting,
  dxOnContextMenuPreparing,
  dxPageSizes,
  dxOnToolbarPreparing,
  dxDateEditorOptions,
  dxOnBeforeSendFunction, dxOnAjaxErrorHandler
} from "@/core/services/devextreme.datagrid.service";
import {useModuleStore} from "@/stores/SystemConfiguration/moduleStore";

const url = systemConfigurationService.moduleGroupResource();

const moduleStore = useModuleStore();


const dataSource = createStore({
  key: 'id',
  loadUrl: `${url}`,
  insertUrl: `${url}`,
  updateUrl: `${url}/update`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: dxOnBeforeSendFunction,
  onAjaxError: dxOnAjaxErrorHandler,
  onInserted: (values, key) => {
    updateModuleGroupsOnTheStore();
  },
  onUpdated: (key, values) => {
    updateModuleGroupsOnTheStore();
  },
  onRemoved: (key) => {
    updateModuleGroupsOnTheStore();
  },
});

const updateModuleGroupsOnTheStore = () => {
  moduleStore.getModuleGroups();
}
</script>
