<template>
  <div id="ModalAddUser" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add User</h4>
          <button
              class="btn-close"
              type="button"
              aria-label="Close"
              @click="closePermissionDetailsModal"
          ></button>
        </div>
        <div id="ModalBodyAddUser" class="modal-body">
          <div class="col-sm-12 col-xl-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-md-12">
                    <div>
                      <div class="d-flex justify-content-between align-items-center">
                        <ul class="nav nav-tabs" id="icon-tab" role="tablist">
                          <li class="nav-item">
                            <a
                                class="nav-link active"
                                id="profile-icon-tab"
                                data-bs-toggle="tab"
                                href="#user-details-users"
                                role="tab"
                                aria-controls="user-details-users"
                                aria-selected="false"
                            >
                              <i class="fa fa-user"></i>
                              Users ({{ filteredData.length }})
                            </a>
                          </li>
                        </ul>

                        <div class="input-search d-flex align-content-center justify-content-between">

                          <input
                              type="text"
                              v-model="searchQuery"
                              class="form-control form-control-sm"
                              placeholder="search..."
                          />
                        </div>
                      </div>

                      <div class="tab-content p-2" id="icon-tabContent">
                        <div
                            class="tab-pane fade show active"
                            id="user-details-users"
                            role="tabpanel"
                            aria-labelledby="icon-home-tab"
                        >
                          <div class="row">
                            <div class="col-md-12 table-container">
                              <table
                                  class="table table-bordered mb-4 table-padding-5-20-5-20 table-hover"
                              >
                                <thead>
                                <tr>
                                  <th>
                                    <input
                                        class="permission-chx"
                                        type="checkbox"
                                        @change="toggleAllCheckboxes"
                                        v-model="allChecked"
                                    />
                                  </th>
                                  <th>ID</th>
                                  <th>Name Surname</th>
                                  <th>Role</th>
                                  <th>Agency</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-if="filteredData.length"
                                    v-for="details in filteredData"
                                    :key="details.id"
                                >
                                  <th>
                                    <input v-if="!details.has_permission"
                                           class="permission-chx"
                                           type="checkbox"
                                           :disabled="details.has_permission"
                                           :checked="details.has_permission"
                                           v-model="details.checked"
                                    />
                                    <input v-else class="permission-chx"
                                           type="checkbox"
                                           :disabled="details.has_permission"
                                           :checked="details.has_permission"
                                    >
                                  </th>
                                  <td>{{ details.id }}</td>
                                  <td>{{ details.name }}</td>
                                  <td>
                                    <RoleBadge :role="details.role?.name"/>
                                  </td>
                                  <td>{{ details.agency?.title }}</td>
                                </tr>
                                <tr v-else>
                                  <td colspan="12" class="text-center text-danger">
                                    There is no data here.
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer d-flex justify-content-between">
          <button
              type="button"
              class="btn btn-secondary"
              @click="closePermissionDetailsModal"
          >
            {{ $translate('common.close') }}
          </button>
          <button @click="saveSelectedRows()"
                  type="button"
                  class="btn btn-primary"
          >
            {{ $translate('common.save_changes') }}
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue';
import {Modal} from 'bootstrap';
import {
  BlockContainer,
  ConfirmWithSwal,
  ToastMessage,
  UnBlocker,
} from '@/core/services/general.service';
import RoleBadge from '@/components/users/mangement/RoleBadge.vue';
import {useDefaultRolePermissionStore} from '@/stores/SystemConfiguration/defaultRolePermissionStore';
import {$translate} from '@/core/services/translator.service';

const emit = defineEmits(['refresh-permission-details']);

const modalAddUser = ref(null);
const drpStore = useDefaultRolePermissionStore();

const the_users = computed(() => drpStore.the_models_who_may_be_added_to_permission);
const searchQuery = ref('');
const allChecked = ref(false);

const saveSelectedRows = async () => {
  const selectedRows = the_users.value.filter(details => details.checked);

  if (!selectedRows.length) {
    ToastMessage('error', 'There are no any changes here! Please select the models that you want to permit.', 'Error');
    return false;
  }

  let loader = BlockContainer('ModalBodyAddUser');

  await drpStore.saveModelsPermission(drpStore.permission_details.id, selectedRows, 'users');
  closePermissionDetailsModal()
  emit('refresh-permission-details')
  UnBlocker(loader);
};

const filteredData = computed(() => {
  if (!searchQuery.value) {
    return the_users.value || [];
  }
  const lowerCaseQuery = searchQuery.value.toLowerCase().trimRight().trimLeft();
  return (
      the_users.value?.filter(
          details =>
              details?.id.toString().toLowerCase().includes(lowerCaseQuery) ||
              details?.name.toLowerCase().includes(lowerCaseQuery) ||
              details?.role.name.toLowerCase().includes(lowerCaseQuery) ||
              details?.role.display_name.toLowerCase().includes(lowerCaseQuery) ||
              details?.agency?.title.toLowerCase().includes(lowerCaseQuery)
      ) || []
  );
});

const getUsersToModal = async () => {
  drpStore.the_models_who_may_be_added_to_permission = null;
  openPermissionDetailsModal();

  let loader = BlockContainer('ModalBodyAddUser');

  await drpStore.getTheModelsWhoMayBeAddedToPermission(drpStore.permission_details.id, 'user');

  UnBlocker(loader);
};

onMounted(() => {
  modalAddUser.value = new Modal(document.getElementById('ModalAddUser'), {
    backdrop: false,
    keyboard: false,
  });
});

const openPermissionDetailsModal = () => {
  modalAddUser.value.show();
};

const closePermissionDetailsModal = () => {
  modalAddUser.value.hide();
};

const toggleAllCheckboxes = () => {
  const checkState = allChecked.value;
  filteredData.value.forEach(details => {
    if (!details.has_permission)
      details.checked = checkState;
  });
};

watch(filteredData, (newValue) => {
  allChecked.value = newValue.length && newValue.every((details) => details.checked);
});

defineExpose({
  getUsersToModal,
});
</script>


<style scoped>
ul.nav-tabs li.nav-item {
  list-style: none;
}

.permission-chx {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.input-search {
  max-width: 45%;
}
</style>
