<template>
  <div id="ModalUserDetails" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">User Details</h4>
          <button
              class="btn-close"
              type="button"
              aria-label="Close"
              @click="closeUserDetailsModal"
          ></button>
        </div>
        <div id="ModalBodyUserDetails" class="modal-body">
          <div class="col-sm-12 col-xl-12">
            <div class="card">
              <div class="card-header p-0 pb-2">
                <div class="row invo-profile">
                  <div class="col-xl-6">
                    <div class="invo-profile-left">
                      <div class="d-flex">
                        <div class="d-flex-left">
                          <img
                              class="d-flex-object rounded-circle img-60"
                              :src="the_user.avatar_path"
                              alt=""
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h5 class="d-flex-heading">
                            {{ the_user?.name }}
                            <span v-if="the_user.deleted_at">
                              <b class="text-danger">#{{ $translate('common.deleted') }}#</b>
                            </span>
                          </h5>
                          <p>
                            {{ the_user?.email }}
                            <br/>
                            <span class="digits">
                              <RoleBadge :role="the_user?.role?.name"/>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="invo-profile-right">
                      <div id="project" class="text-xl-end">
                        <h6 class="fw-bolder text-dark">{{ the_user.agency?.title }}</h6>
                        <p class="m-0">
                          {{ the_user.agency?.country?.name }} /
                          {{ the_user.agency?.state?.name }} /
                          {{ the_user.agency?.city?.name }}
                        </p>
                        <p class="m-0">{{ the_user.agency?.customized?.address }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="icon-tab" role="tablist">
                  <li class="nav-item">
                    <a
                        class="nav-link"
                        :class="{ active: activeTab === 'user' }"
                        id="profile-icon-tab"
                        data-bs-toggle="tab"
                        href="#user-details-users"
                        role="tab"
                        aria-controls="user-details-users"
                        aria-selected="false"
                        @click="setActiveTab('user')"
                    >
                      <i class="icofont icofont-man-in-glasses"></i>
                      {{ $translate('users.management.details_modal.tabs.user.title') }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                        class="nav-link"
                        :class="{ active: activeTab === 'settings' }"
                        id="contact-icon-tab"
                        data-bs-toggle="tab"
                        href="#user-details-settings"
                        role="tab"
                        aria-controls="user-details-settings"
                        aria-selected="false"
                        @click="setActiveTab('settings')"
                    >
                      <i class="fa fa-cogs"></i>
                      {{ $translate('users.management.details_modal.tabs.settings.title') }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                        class="nav-link"
                        id="contact-icon-tab"
                        data-bs-toggle="tab"
                        href="#agency-details-settings"
                        role="tab"
                        aria-controls="agency-details-settings"
                        aria-selected="false"
                    >
                      <i class="fa fa-lock"></i>
                      Permissions
                    </a>
                  </li>
                </ul>
                <div class="tab-content p-2" id="icon-tabContent">
                  <div
                      class="tab-pane fade"
                      :class="{ 'show active': activeTab === 'user' }"
                      id="user-details-users"
                      role="tabpanel"
                      aria-labelledby="icon-home-tab"
                  >
                    <UserGeneral
                        :user="the_user"
                        @agency-details="(id) => agencyDetailsRef.getAgencyDetailsToModal(id)"
                        @user-details-refresh="(id) => userDetailsRefresh(id)"
                    />
                  </div>

                  <div
                      class="tab-pane fade"
                      :class="{ 'show active': activeTab === 'settings' }"
                      id="user-details-settings"
                      role="tabpanel"
                      aria-labelledby="contact-icon-tab"
                  >
                    <UserSettings :user="the_user" @user-details-refresh="(id) => userDetailsRefresh(id)"
                                  :modal-model-status="modalModelStatus"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeUserDetailsModal">
            {{ $translate('common.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <AgencyDetailGm ref="agencyDetailsRef"/>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import {Modal} from 'bootstrap';
import {BlockContainer, UnBlocker} from '@/core/services/general.service';
import {useUserStore} from '@/stores/userStore';
import {$translate} from '@/core/services/translator.service';
import AgencyDetailGm from '@/components/Agency/Details/AgencyDetailGm.vue';
import RoleBadge from '@/components/users/mangement/RoleBadge.vue';
import UserGeneral from '@/components/users/mangement/includes/UserGeneral.vue';
import UserSettings from '@/components/users/mangement/includes/UserSettings.vue';
import ModalModelStatus from "@/components/DetailModals/ModalModelStatus.vue";


const modalModelStatus = ref(null);

const emit = defineEmits(['close-modal', 'refresh-users-datagrid']);


const agencyDetailsRef = ref(null);


const userStore = useUserStore();
const the_user = computed(() => userStore.selected_user);

const activeTab = ref('user');

const getUserDetailsToModal = async (user_id) => {
  openUserDetailsModal();

  activeTab.value = 'user';

  const loader = BlockContainer('ModalBodyUserDetails');
  const response = await userStore.getUserDetails(user_id);

  if (!response) {
    closeUserDetailsModal();
  }

  UnBlocker(loader);
};

const userDetailsRefresh = (id) => {
  getUserDetailsToModal(id);
  emit('refresh-users-datagrid');
};

const modalUserDetails = ref(null);

onMounted(() => {
  modalUserDetails.value = new Modal(document.getElementById('ModalUserDetails'), {
    backdrop: false,
    keyboard: false,
  });
});

const openUserDetailsModal = () => {
  modalUserDetails.value.show();
};

const closeUserDetailsModal = () => {
  modalUserDetails.value.hide();
};

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

defineExpose({
  getUserDetailsToModal,
});
</script>

<style scoped>
ul.nav-tabs li.nav-item {
  list-style: none;
}
</style>
