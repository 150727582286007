<template>
  <table
      class="table table-bordered table-striped mb-4 table-padding-5-20-5-20"
      v-if="modelIsDeleted"
  >
    <thead>
    <tr>
      <th colspan="2" class="text-center fw-bolder">
        {{ $translate('common.delete_information') }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="info">
        {{ $translate('common.deleted_at') }}
      </td>
      <td>{{ model.deleted_at }}</td>
    </tr>
    <tr>
      <td>
        {{ $translate('common.deleted_by') }}
      </td>
      <td>{{ model.deleted_by_user?.name }}
        (
        <RoleBadge :role="model.deleted_by_user?.role?.name"/>
        )
      </td>
    </tr>
    <tr>
      <td>
        {{ $translate('common.label_delete_rollback') }}
      </td>
      <td>
        <button @click="deleteConfirm(model.id)" class="btn btn-outline-secondary btn-xs">
          {{ $translate('common.btn_delete_rollback') }}
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script setup>
import RoleBadge from "@/components/users/mangement/RoleBadge.vue";
import {ConfirmWithSwal} from "@/core/services/general.service";
import {computed} from "vue";
import {$translate} from "@/core/services/translator.service";

const emit = defineEmits(['after-delete-rollback'])

const modelIsDeleted = computed(() => props.model.deleted_at)

const props = defineProps({
  model: {
    type: Object,
    required: true,
  },
  deleteAction: {
    type: Function,
    required: true,
  },
  rollbackConfirmMessage: {
    type: String,
    default: $translate('common.rollback_info_default_message'),
  }
})

const deleteConfirm = async () => {
  let result = await ConfirmWithSwal('warning', props.rollbackConfirmMessage, $translate('common.rollback_message_title'))

  if (!result)
    return false;

  props.deleteAction(props.model.id);
}
</script>