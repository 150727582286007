<template>
  <div id="ModalModuleForm" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ title }}</h4>
          <button class="btn-close" type="button" aria-label="Close"
                  @click="emit('close-modal')"></button>
        </div>
        <div class="modal-body">
          <form id="formModule" class="needs-validation" novalidate="" @submit.prevent="handleSubmit()">
            <div class="row g-3">
              <div class="col-md-6">
                <label class="form-label">Modül Grubu</label>
                <select name="moduleGroup" required
                        v-bind:class="isValid.module_group ? 'is-valid' : 'is-invalid'"
                        v-model="formData.module_group" id="moduleGroup" class="form-control">
                  <option value="">Seçiniz</option>
                  <option v-for="mg in moduleStore.moduleGroups" :key="mg.id" :value="mg.id">{{ mg.name }}</option>
                </select>
              </div>
              <div class="col-md-6">
                <label class="form-label" for="validationCustom02">Modül Adı</label>
                <input class="form-control" type="text"
                       v-bind:class="isValid.name ? 'is-valid' : 'is-invalid'"
                       placeholder="Your name" v-model="formData.name">
              </div>
            </div>
            <div class="row g-3">
              <div class="col-md-6 mb-3">
                <label class="form-label" for="validationCustom02">Icon</label>
                <input class="form-control" type="text"
                       v-bind:class="isValid.ico ? 'is-valid' : 'is-invalid'"
                       id="icon" required
                       placeholder="Your name" v-model="formData.ico">
              </div>
              <div class="col-md-6 mb-3">
                <label class="form-label" for="validationCustom02">Lokasyon</label>
                <select name="location" v-model="formData.location" id="location"
                        v-bind:class="isValid.location ? 'is-valid' : 'is-invalid'"
                        class="form-control">
                  <option value="">Seçiniz</option>
                  <option v-for="location in moduleStore.moduleLocations" :key="location.id" :value="location.name">
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-md-12 mb-3">
                <label class="form-label" for="validationCustom02">Açıklama</label>
                <textarea name="description" v-model="formData.description" id="description" cols="30" rows="3"
                          v-bind:class="isValid.description ? 'is-valid' : 'is-invalid'"
                          class="form-control"
                          placeholder="Zorunlu Alan"></textarea>
              </div>
            </div>
            <div class="mb-3">
              <div class="form-check">
                <div class="checkbox p-0">
                  <input class="form-check-input" v-model="formData.no_sub_module" id="invalidCheck" type="checkbox">
                  <label class="form-check-label" for="invalidCheck">Tekil modül</label>
                </div>
              </div>
            </div>
            <div v-if="formData.no_sub_module" class="row g-3">
              <div class="col-md-6 mb-3">
                <label class="form-label" for="validationCustom02">Route Name:</label>
                <input v-model="formData.route_name"
                       v-bind:class="isValid.route_name ? 'is-valid' : 'is-invalid'"
                       class="form-control" type="text"
                       placeholder="Route name">
              </div>
              <div class="col-md-6 mb-3">
                <label class="form-label" for="validationCustom02">Path:</label>
                <input class="form-control" type="text"
                       v-bind:class="isValid.path ? 'is-valid' : 'is-invalid'"
                       placeholder="Path" v-model="formData.path">
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">

          <button type="button" class="btn btn-secondary" @click="emit('close-modal')">Close</button>
          <button type="submit" form="formModule" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useModuleStore} from "@/stores/SystemConfiguration/moduleStore";
import {computed, reactive, watch} from "vue";
import {ToastMessage} from "@/core/services/general.service";

const emit = defineEmits([
  'close-modal',
  'refresh-datagrid'
]);

const props = defineProps({
  title: {
    type: String,
    required: true
  },
});

const moduleStore = useModuleStore();
moduleStore.getModuleGroups();



const formData = reactive({
  id: null,
  module_group: null,
  name: null,
  ico: null,
  location: null,
  description: null,
  no_sub_module: false,
  route_name: null,
  path: null
});

const isValid = reactive({
  formValid: computed(() => {
    return isValid.module_group &&
        isValid.name &&
        isValid.ico &&
        isValid.location &&
        isValid.description &&
        isValid.no_sub_module &&
        isValid.route_name &&
        isValid.path;
  }),
  module_group: computed(() => formData.module_group !== null),
  name: computed(() => formData.name && formData.name.length > 0),
  ico: computed(() => formData.ico && formData.ico.length > 0),
  location: computed(() => formData.location && formData.location.length > 0),
  description: computed(() => formData.description && formData.description.length > 0),
  no_sub_module: computed(() => formData.no_sub_module !== null),
  route_name: computed(() => formData.no_sub_module ? (formData.route_name && formData.route_name.length > 0) : true),
  path: computed(() => true),
});

const handleSubmit = async () => {
  if (!isValid.formValid) {
    ToastMessage('error', 'Lütfen kırmızı ile işaretlenen alanları düzenleyiniz!', 'Hata!');
    return false;
  }

  try {

    let response = null;
    if (moduleStore.editMode)
      response = await moduleStore.editModule(formData);
    else
      response = await moduleStore.createModule(formData);

    if (response?.status === 200) {
      ToastMessage('success', response.data.message, 'Başarılı!');
      clearData();

      emit('close-modal');
      emit('refresh-datagrid');
    }


  } catch (e) {
    throw e;
  }
}


const clearData = () => {
  Object.assign(formData, {
    id: null,
    module_group: null,
    name: null,
    ico: null,
    location: null,
    description: null,
    no_sub_module: false,
    route_name: null,
    path: null
  });
}

watch(() => moduleStore.getEditModuleData, (newData) => {
  if (newData)
    Object.assign(formData, newData);
  else
    clearData();

}, {immediate: true});
</script>

