import {defineStore} from "pinia";
import router from "@/router";
import {ToastMessage} from "@/core/services/general.service";
import {authService} from "@/core/api/auth/auth.api";
import {apiClient} from "@/core/common/http-common";
import store from "@/store";
import {$translate} from "@/core/services/translator.service";
import {errorService} from "@/core/services/error.service";
import {useAgencyStore} from "@/stores/agencyStore";
import {toRaw} from "vue";

export const useAuthStore = defineStore("authStore", {
    state: () => ({
        user: null,
        agency: null,
        token: null,
        module_tree: [],
        languages: [],
        currencies: [],
        all_currencies: [],
        token_expires_at: null,
    }),
    getters: {
        currentCurrency: (state) => {
            let activeCurrency = null;

            if (Array.isArray(state.currencies)) {
                activeCurrency = state.currencies.find(currency => currency.default);
            }

            if (!activeCurrency && state.currencies.length > 0) {
                activeCurrency = state.currencies[0];
            }

            // console.log("here is the active currency: ", activeCurrency, state.currencies);
            return activeCurrency;
        }
    },
    actions: {
        async login(credentials) {
            try {
                const response = await authService.login(credentials);
                this.user = response.data.user;
                this.agency = response.data.agency;
                this.token = response.data.token;
                this.module_tree = response.data.module_tree;
                this.module_tree = this.parseModuleTree();
                this.applyMenuTree();

                this.languages = this.parseLanguages(response.data.languages);
                this.token_expires_at = response.data.token_expires_at;


                this.currencies = Object.values(response.data.currencies);
                this.all_currencies = Object.values(response.data.all_currencies);
                this.initCurrency();

                localStorage.setItem("token", this.token);
                localStorage.setItem("user", JSON.stringify(this.user));
                localStorage.setItem("module_tree", JSON.stringify(this.module_tree));
                localStorage.setItem("languages", JSON.stringify(this.languages));
                localStorage.setItem("currentLanguage", this.languages[0].id);
                localStorage.setItem("f_dgx", response.data.f_dgx);
                localStorage.setItem("currency", this.currentCurrency.code);
                localStorage.setItem("token_expires_at", this.token_expires_at);
                localStorage.setItem("timezone", this.agency.timezone);


                this.initLanguages();
                this.refreshForLangVersion();
                this.determineLayout()

                apiClient.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;

                ToastMessage(
                    "success",
                    $translate("system.messages.login_successfully", {name_surname: this.user.name}),
                    $translate("system.messages.login_successfully_title")
                );
                window.location.reload();
                // await router.push("/");
            } catch (e) {
                throw e;
            }
        },

        determineLayout() {
            let layoutName = null;

            switch (this.user.role) {

                case 'reservationist':
                case 'agency_operator':
                case 'agency_admin':
                    layoutName = 'Singapore';
                    break;

                default:
                    layoutName = 'Dubai';
                    break;
            }

            localStorage.setItem("layoutName", layoutName);

        },

        initLanguages() {

            const languages = this.languages;
            const storedLanguage = localStorage.getItem("currentLanguage");

            const agencyDefaultLanguage = this.agency.default_language;
            const agencyDefaultLanguageIcon = languages.find((lang) => lang.id === agencyDefaultLanguage)?.icon;

            if (storedLanguage) {

                const storedLanguageExists = languages.find((lang) => lang.id === storedLanguage);
                if (!storedLanguageExists) {
                    localStorage.setItem("currentLanguage", agencyDefaultLanguage);
                    localStorage.setItem("currentLanguageIcon", agencyDefaultLanguageIcon);
                }

            } else {
                localStorage.setItem("currentLanguage", agencyDefaultLanguage);
                localStorage.setItem("currentLanguageIcon", agencyDefaultLanguageIcon);
            }

            // console.log("coming langs : ", languages, "agency default language: ", agencyDefaultLanguage, " and icon:", agencyDefaultLanguageIcon);


        },

        refreshForLangVersion(force = false) {
            const dgx = localStorage.getItem("dgx");
            const f_dgx = localStorage.getItem("f_dgx");
            // console.log("dgx => ", dgx, " f dgx => ", f_dgx);

            if (force || (dgx !== f_dgx))
                ToastMessage("info", "Please Wait, Lang Updating...", "Info!");


            setTimeout(() => {
                if (force) {
                    localStorage.removeItem("dgx");
                    location.reload();
                } else {
                    if (dgx !== f_dgx) {
                        ToastMessage("info", "Lang Updating...", "Info!");
                        window.location.href = "/";
                    }
                }
            }, 1200);
        },

        async logout(forced = false) {
            try {
                // console.trace("logout called");
                // const response = await authService.logout();

                this.user = null;
                this.token = null;
                this.token_expires_at = null;

                localStorage.removeItem("user");
                localStorage.removeItem("token");
                localStorage.removeItem('token_expires_at');

                await router.push("/login");

                if (!forced)
                    ToastMessage(
                        "success",
                        $translate("system.messages.logout_successfully"),
                        $translate("system.messages.logout_successfully_title")
                    );
            } catch (error) {
                throw error;
            }
        },

        async authControl() {
            try {
                const response = await authService.authControl();
                this.module_tree = response.data.module_tree;
                this.currencies = Object.values(response.data.currencies);
                this.all_currencies = Object.values(response.data.all_currencies);

                this.agency = response.data.agency;

                this.languages = this.parseLanguages(response.data.languages);

                this.initLanguages();

                this.token_expires_at = response.data.token_expires_at;
                localStorage.setItem("token_expires_at", this.token_expires_at);
                localStorage.setItem("timezone", this.agency.timezone);

                this.initCurrency();
                this.determineLayout()

                localStorage.setItem("f_dgx", response.data.f_dgx);
                this.parseModuleTree();
            } catch (e) {
                errorService.apiClientError(e);
            }
        },

        parseModuleTree() {
            const transformedData = [];

            this.module_tree.forEach((group) => {
                transformedData.push({
                    headTitle1: group.module_group,
                    headTitle1Slug: group.slug,
                    type: "headtitle",
                });

                group.modules.forEach((module) => {
                    const children = module.sub_modules.map((subModule) => ({
                        path: subModule.url,
                        title: subModule.name,
                        slug: subModule.slug,
                        type: "link",
                        active: false,
                    }));

                    transformedData.push({
                        title: module.name,
                        slug: module.slug,
                        icon: module.ico,
                        type: "sub",
                        badgeType: "light-primary",
                        badgeValue: "", // Bu alanı istenen şekilde doldurabilirsiniz.
                        active: false,
                        children: children,
                    });
                });
            });

            return transformedData;
        },

        parseLanguages(data) {

            if (!Array.isArray(data))
                data = Object.keys(data).map(key => data[key]);

            return data.map((lang) => {
                let language = {
                    id: lang.Slug,
                    name: lang.LangName,
                    icon: `flag-icon-${lang.Flag}`,
                    lang_code: lang.LangCode,
                    dir: lang.Dir,
                };

                return language;
            });
        },

        applyMenuTree() {
            store.dispatch("menu/updateData", this.module_tree);
        },

        applyCurrentLanguage(lang) {
            store.dispatch("setLang", lang);
        },

        async authInit() {
            const storedUser = localStorage.getItem("user");
            const storedToken = localStorage.getItem("token");
            const storedModuleTree = localStorage.getItem("module_tree");
            const storedLanguages = localStorage.getItem("languages");
            const storedCurrentLanguage = localStorage.getItem("currentLanguage");

            const authStore = useAuthStore();

            if (storedUser && storedToken && storedModuleTree) {
                authStore.user = JSON.parse(storedUser);
                authStore.token = storedToken;
                authStore.module_tree = JSON.parse(storedModuleTree);
                authStore.languages = JSON.parse(storedLanguages);


                const currentLanguageObj = authStore.languages.find((lang) => lang.id === storedCurrentLanguage);

                apiClient.defaults.headers.common["Authorization"] = `Bearer ${storedToken}`;

                authStore.applyMenuTree();

                if (currentLanguageObj) {
                    authStore.applyCurrentLanguage(currentLanguageObj);
                } else {
                    console.warn("Current language not found in stored languages");
                }


                await authStore.authControl();
            }
        },

        initCurrency() {
            const storedCurrencyCode = localStorage.getItem("currency");
            const defaultCurrencyFromApi = useAuthStore().currentCurrency?.code;


            this.currencies = Object.values(toRaw(this.currencies));
            console.log("curr", this.currencies);

            const selectedCurrency = storedCurrencyCode
                ? this.currencies.find((cur) => cur.code === storedCurrencyCode)
                : null;

            // console.log("here is the selected currency", selectedCurrency);

            const finalCurrency = selectedCurrency || this.currencies.find((cur) => cur.code === defaultCurrencyFromApi);

            // console.log("finalCurrency:", finalCurrency);

            if (finalCurrency) {
                this.currencies.forEach((cur) => {
                    cur.default = false;
                });
                finalCurrency.default = true;
            } else {
                console.warn("No valid currency found in stored data or from API.");
            }
        },

        checkTokenExpiry() {

            const storedTokenExpiresAt = localStorage.getItem('token_expires_at');

            if (!storedTokenExpiresAt)
                return;

            const now = new Date();
            const expiryDate = new Date(storedTokenExpiresAt);

            if (now >= expiryDate) {
                ToastMessage('info', 'Your session have expired! Please login again.', 'Info');
                this.logout(true);
            }
        },

    },
});
