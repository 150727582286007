<template>
  <div class="tab-pane fade" :class="{ 'active show': customizer == 'layouts' }" id="c-pills-home">
    <ul class="sidebar-type layout-grid layout-types">

      <li class="only-body" data-attr="default-body">
        <div class="layout-img">
          <a @click="handlePageLayputs('only-body', 'London')">
          </a>
          <h6>London</h6>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import {layoutClasses} from '@/core/constants/layout';

export default {
  name: 'Customlayout',
  computed: {
    ...mapState({
      customizer: (state) => state.menu.customizer,
      layout: (state) => state.layout.layout,
      menuItems: state => state.menu.data,
      togglesidebar: (state) => state.menu.togglesidebar,
    }),
  },
  methods: {
    handlePageLayputs(val, layout) {
      const layoutobj = layoutClasses.find((item) => Object.keys(item).pop() === layout);
      this.$store.dispatch('layout/setCustomizeSidebarType', layoutobj[layout]);
      this.layout.settings.layout = layout;
      this.$router.push({query: {layout: layout}}).catch(err => err);
      if (!layoutobj[layout].split(' ').includes('compact-sidebar')) {
        this.$store.state.menu.activeoverlay = true;
        document.getElementById('sidebar-menu').classList.remove('d-flex');
      } else {
        this.$store.state.menu.activeoverlay = false;
        document.getElementById('sidebar-menu').classList.add('d-flex');
      }
      if (layout === 'Dubai') {
        this.$store.state.layout.boxlayout = true;
      } else {
        this.$store.state.layout.boxlayout = false;
      }
      if (layout === 'Tokyo' || layout === 'Moscow' || layout === 'Rome') {
        this.$store.state.menu.togglesidebar = true;
      }
    },
  },
};
</script>
