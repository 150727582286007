<template>
  <div class="container-fluid">
    <Breadcrumbs :main="$translate('agencies.management.title1')"/>
    <div class="row starter-main">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>{{ $translate('agencies.management.title2') }}</h5>
            <div class="card-header-right">
              <ul class="list-unstyled card-option">
                <li><i class="fa fa-spin fa-cog"></i></li>
                <li><i class="view-html fa fa-code"></i></li>
                <li><i class="icofont icofont-maximize full-card"></i></li>
                <li><i class="icofont icofont-minus minimize-card"></i></li>
                <li><i class="icofont icofont-refresh reload-card"></i></li>
                <li><i class="icofont icofont-error close-card"></i></li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <DxDataGrid
                ref="dataGridRef"
                :show-borders="true"
                :data-source="dataSource"
                :show-row-lines="true"
                :show-column-lines="true"
                :row-alternation-enabled="true"
                :remote-operations="false"
                :column-auto-width="true"
                :allow-column-resizing="true"
                :column-resizing-mode="'widget'"
                :width="'100%'"
                :height="700"
                @toolbarPreparing="dxOnToolbarPreparing"
                @contextMenuPreparing="dxOnContextMenuPreparing"
                @exporting="
                (e) => dxOnExcelExporting(e, 'Acenteler', 'Acenteler')
              "
            >
              <DxExport :enabled="true" :allow-export-selected-data="false"/>
              <DxPaging :page-size="500"/>
              <DxStateStoring
                  :enabled="true"
                  type="localStorage"
                  storage-key="storage"
              />

              <DxPager
                  :visible="true"
                  :allowed-page-sizes="dxPageSizes"
                  :display-mode="'compact'"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
              />

              <DxColumn
                  data-field="title"
                  :caption="$translate('agencies.management.title')"
              />
              <DxColumn
                  :group-index="0"
                  data-field="parent_agency_name"
                  :caption="$translate('agencies.management.parent_agency_name')"
              />

              <DxColumn
                  data-field="country_name"
                  :caption="$translate('agencies.management.country')"
              />
              <DxColumn
                  data-field="city_name"
                  :visible="false"
                  :caption="$translate('agencies.management.city')"
              />
              <DxColumn
                  data-field="customized.phone"
                  :caption="$translate('agencies.management.phone')"
              />
              <DxColumn
                  data-field="customized.address"
                  :visible="false"
                  :caption="$translate('agencies.management.address')"
              />
              <DxColumn
                  data-field="customized.fax"
                  :visible="false"
                  :caption="$translate('agencies.management.fax')"
              />
              <DxColumn
                  data-field="customized.post_code"
                  :visible="false"
                  :caption="$translate('agencies.management.post_code')"
              />
              <DxColumn data-field="web_site" caption="Web Site"/>

              <DxColumn
                  data-field="email"
                  :caption="$translate('agencies.management.email')"
                  :visible="false"
              />
              <DxColumn
                  data-field="agency_code"
                  :caption="$translate('agencies.management.agency_code')"
              />
              <DxColumn
                  data-field="status"
                  :caption="$translate('agencies.management.status')"
                  cell-template="statusTemplate"
              />
              <template #statusTemplate="{ data: rowInfo }">
                <b
                    :class="rowInfo.data.status ? 'text-success' : 'text-danger'"
                >
                  {{
                    rowInfo.data.status
                        ? $translate('common.active')
                        : $translate('common.pasif')
                  }}
                </b>
              </template>

              <DxColumn
                  data-field="created_at_format"
                  data-type="date"
                  :caption="$translate('agencies.management.created_at')"
                  :allow-editing="false"
                  sort-order="desc"
                  :editor-options="dxDateEditorOptions"
              ></DxColumn>

              <DxFilterRow :visible="true"/>
              <DxHeaderFilter :visible="true"/>

              <DxEditing
                  :allow-adding="false"
                  :allow-updating="false"
                  :allow-deleting="true"
                  mode="row"
              />

              <DxColumn type="buttons">
                <DxButton icon="edit" @click="(e) => editAgency(e)"/>
                <DxButton name="delete"/>
              </DxColumn>
              <DxColumn
                  data-field="id"
                  caption="Detay"
                  cell-template="detailButtonTemplate"
              />
              <template #detailButtonTemplate="{ data: rowInfo }">
                <button
                    class="btn btn-primary btn-sm"
                    @click="
                    agencyDetailGm.getAgencyDetailsToModal(rowInfo.data.id)
                  "
                >
                  {{ $translate('agencies.management.detail') }}
                  <i class="fa fa-search ms-1"></i>
                </button>
              </template>

              <DxSearchPanel :visible="true" :highlight-case-sensitive="true"/>
              <DxColumnChooser :enabled="true"/>

              <DxGroupPanel :visible="true"/>
              <DxGrouping :auto-expand-all="true"/>

              <DxToolbar>
                <DxItem location="after">
                  <button @click="createModule()" class="btn btn-primary btn-xs">
                    <i class="fa fa-plus"></i>
                  </button>
                </DxItem>
                <DxItem name="columnChooserButton"/>
                <DxItem name="exportButton"/>
                <DxItem name="searchPanel"/>
                <DxItem name="groupPanel"/>
              </DxToolbar>

              <DxSummary>
                <DxGroupItem
                    column="parent_agency_name"
                    summary-type="count"
                    :display-format="
                    $translate('agencies.management.summery_count1', {
                      zero: '{0}',
                    })
                  "
                />
                <DxTotalItem
                    column="title"
                    summary-type="count"
                    :display-format="
                    $translate('agencies.management.summery_count2', {
                      zero: '{0}',
                    })
                  "
                />
              </DxSummary>
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>

    <ModalAgencyForm
        :title="formTitle"
        @close-modal="closeModal()"
        @refresh-datagrid="dataGridRef.instance.refresh()"
        ref="modalAgencyForm"
    />

    <AgencyDetailGm ref="agencyDetailGm"/>
  </div>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxPaging,
  DxPager,
  DxExport,
  DxButton,
  DxToolbar,
  DxItem,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
  DxSummary,
  DxGroupItem,
  DxTotalItem,
  DxToolbarItem,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import {createStore} from 'devextreme-aspnet-data-nojquery';
import {agencyService} from '@/core/api/agency.api';
import {
  dxOnExcelExporting,
  dxOnContextMenuPreparing,
  dxPageSizes,
  dxOnToolbarPreparing,
  dxDateEditorOptions,
  dxOnBeforeSendFunction,
  dxOnAjaxErrorHandler,
} from '@/core/services/devextreme.datagrid.service';

import {onMounted, ref} from 'vue';
import ModalAgencyForm from '@/pages/agencies/management/modals/ModalAgencyForm.vue';
import AgencyDetailGm from '@/components/Agency/Details/AgencyDetailGm.vue';
import {useAgencyStore} from '@/stores/agencyStore';
import {$translate} from '@/core/services/translator.service';

const agencyDetailGm = ref(null);

const modalAgencyForm = ref(null);


const dataGridRef = ref();

const url = agencyService.agenciesResource();

const agencyStore = useAgencyStore();

const dataSource = createStore({
  key: 'id',
  loadUrl: `${url}`,
  insertUrl: `${url}`,
  updateUrl: `${url}/update`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: dxOnBeforeSendFunction,
  onAjaxError: dxOnAjaxErrorHandler,
  onInserted: (values, key) => {
    updateModuleGroupsOnTheStore();
  },
  onUpdated: (key, values) => {
    updateModuleGroupsOnTheStore();
  },
  onRemoved: (key) => {
    updateModuleGroupsOnTheStore();
  },
});

const updateModuleGroupsOnTheStore = () => {
};

const formTitle = ref('Acente Oluştur');

const createModule = () => {
  agencyStore.edit_mode = false;
  agencyStore.edit_details = null;
  formTitle.value = $translate('agencies.agency_form.create_agency');
  modalAgencyForm.value.openModal();
};

const editAgency = (e) => {
  agencyStore.edit_details = e.row.data;
  agencyStore.edit_mode = true;

  console.log("here is the agency details: ", e.row.data);

  formTitle.value = $translate('agencies.agency_form.edit_agency');
  modalAgencyForm.value.openModal(e.row.data);
};

/* Modal Agency Details */
</script>
