import {apiClient, apiClientFullURL} from "@/core/common/http-common";

export default class UserService {
    const
    base_url = apiClientFullURL;

    usersResource() {
        return this.base_url + "/Users/Management/Resource";
    }

    async getUserDetails(user_id) {
        return await apiClient.get(`/Users/Management/Resource/${user_id}`);
    }

    async getPossibleRoles() {
        return await apiClient.get(`/Users/Management/Transactions/GetAvailableRoles`);
    }

    async createUser(user_data) {
        return await apiClient.post(`/Users/Management/Resource`, user_data);
    }

    async updateUser(user_data) {
        return await apiClient.put(`/Users/Management/Resource/${user_data.id}`, user_data);
    }

    async deleteUser(id) {
        return await apiClient.delete(`/Users/Management/Resource/${id}`, {
            data: {key: id}
        });
    }

    async rollbackUser(id) {
        return await apiClient.post(`/Users/Management/Transactions/DeleteRollback`, {id});
    }

    async resetPassword(user_id) {
        return await apiClient.post(`/Users/Management/Transactions/ResetUserPassword`, {user_id});
    }

    async changeStatus(status_data) {
        return await apiClient.post(`/Users/Management/Transactions/ChangeStatus`, status_data);
    }
}

export const userService = new UserService();