<template>
  <div id="ModalWalletForm" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ editMode ? 'Edit Agency Wallet' : 'Create Agency Wallet' }}</h4>
          <button
              class="btn-close"
              type="button"
              aria-label="Close"
              @click="closeModal"
          ></button>
        </div>
        <div id="ModalBodyWalletForm" class="modal-body">

          <div class="row">
            <div class="col-md-12 mb-4">
              <DxSelectBox
                  label="Currency:"
                  labelMode="outside"
                  v-model="formData.currency"
                  :items="authStore.currencies"
                  displayExpr="code"
                  valueExpr="code"
                  :read-only="editMode"
              >
              </DxSelectBox>
            </div>


            <div class="col-md-12 mb-4">
              <DxSelectBox
                  label="Status:"
                  labelMode="outside"
                  v-model="formData.status"
                  :items="[{text:'Active', value: true}, {text:'Passive', value:false}]"
                  displayExpr="text"
                  valueExpr="value"
                  :read-only="false"
              >
              </DxSelectBox>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            {{ $translate('common.close') }}
          </button>
          <button type="button" class="btn btn-primary" @click="handleSave">
            {{ $translate('common.save_changes') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {Modal} from 'bootstrap';
import {$translate} from "@/core/services/translator.service";
import DxSelectBox from "devextreme-vue/select-box";
import {useAuthStore} from "@/stores/authStore";
import {useAgencyStore} from "@/stores/agencyStore";
import {errorService} from "@/core/services/error.service";
import {BlockContainer, ToastMessage, UnBlocker} from "@/core/services/general.service";
import {bool} from "yup";

const authStore = useAuthStore();
const agencyStore = useAgencyStore();
const editMode = ref(false);
const emit = defineEmits(['refresh-datagrid']);

const handleSave = async () => {


  const loader = BlockContainer('ModalBodyWalletForm');


  try {
    let response;

    if (editMode.value)
      response = await agencyStore.updateAgencyWallet(formData.value)
    else
      response = await agencyStore.createAgencyWallet(formData.value)

    ToastMessage('success', response.data.message, $translate('common.successfully'));
    emit('refresh-datagrid');

    closeModal();

  } catch (e) {
    errorService.apiClientError(e);
  } finally {
    UnBlocker(loader);
  }

}

const formData = ref({
  wallet_id: null,
  currency: authStore.currentCurrency?.code,
  status: true,
});

const modalWalletForm = ref()

onMounted(() => {
  modalWalletForm.value = new Modal(document.getElementById('ModalWalletForm'), {
    backdrop: false,
    keyboard: false,
  });
});

const openModal = (paramEditMode = false, editData = {}) => {
  editMode.value = paramEditMode;

  if (paramEditMode) {
    formData.value.wallet_id = editData.id;
    formData.value.currency = editData.currency_code;
    formData.value.status = editData.status === 1;
  } else {
    formData.value.wallet_id = null;
    formData.value.currency = null;
    formData.value.status = true;
  }

  modalWalletForm.value.show();
};

const closeModal = () => {
  modalWalletForm.value.hide();
};


defineExpose({
  openModal,
  closeModal
});
</script>

<style scoped>

</style>
