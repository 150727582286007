import { defineStore } from "pinia";
import { translationService } from "@/core/api/translation.api";

export const useTranslationStore = defineStore("translationStore", {
  state: () => ({}),
  getters: {},
  actions: {
    async updateTranslations(module_name, postData) {
      return await translationService
        .updateModule(module_name, postData)
        .then((response) => true)
        .catch((error) => {
          console.log(error);
          console.error("error@translationStore@updateModule()");
          return false;
        });
    },
  },
});
