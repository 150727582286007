import {apiClient, apiClientFullURL} from "@/core/common/http-common";

export default class AgencyService {
    const
    base_url = apiClientFullURL;

    agenciesResource() {
        return this.base_url + "/Agencies/Agency";
    }

    agencyWalletResource(agency_id) {
        return this.base_url + `/Agencies/AgencyWallet?agency_id=${agency_id}`;
    }

    async createAgency(agency_data) {
        return await apiClient.post(`/Agencies/Agency`, agency_data);
    }

    async updateAgency(agency_data) {
        return await apiClient.put(`/Agencies/Agency/${agency_data.id}`, agency_data);
    }

    async getAgencies() {
        return await apiClient.get(`/Agencies/GetAgencies`);
    }


    async getCreateFormData() {
        return await apiClient.get(`/Agencies/GetCreateFormData`);
    }

    async getActiveLanguages() {
        return await apiClient.get(`/Agencies/GetActiveLanguages`);
    }

    async getCurrencies() {
        return await apiClient.get(`/Agencies/GetCurrencies`);
    }

    async saveAgencyParentRelationship(agency_data) {
        return await apiClient.post(`/Agencies/SaveAgencyRelationship`, agency_data);
    }

    async getAgencyDetails(agency_id) {
        return await apiClient.get(`/Agencies/Agency/${agency_id}`);
    }

    async getDiagramData() {
        return await apiClient.get(`/Agencies/GetDiagramData`);
    }

    async createAgencyMarkup(data) {
        return await apiClient.post(`/Agencies/Transactions/CreateOrUpdateAgencyMarkup`, data);
    }

    async updateAgencyMarkup(data) {
        return await apiClient.put(`/Agencies/Transactions/CreateOrUpdateAgencyMarkup`, data);
    }

    async deleteAgencyMarkup(markup_id) {
        return await apiClient.post(`/Agencies/Transactions/DeleteAgencyMarkup`, {markup_id: markup_id});
    }

    /**
     * Default Markups
     * */

    defaultMarkupsResource() {
        return this.base_url + "/Agencies/DefaultMarkups";
    }

    /**
     * Wallet Transactions
     * */

    async createAgencyWallet(agency_id, data) {
        return await apiClient.post(`/Agencies/${agency_id}/AgencyWallet`, data);
    }

    async updateAgencyWallet(agency_id, data) {
        return await apiClient.put(`/Agencies/${agency_id}/AgencyWallet/${data.wallet_id}`, data);
    }

    async loadBalance(agency_id, data) {
        return await apiClient.put(`/Agencies/${agency_id}/Wallet/Transactions/LoadBalance`, data);
    }

    /**
     * Dashboard Transactions
     * */

    async getLatestReservations() {
        return await apiClient.get(`/Agencies/Transactions/GetLatestReservations`);
    }
}

export const agencyService = new AgencyService();