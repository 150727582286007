<template>
  <router-link to="/">
    <img
      class="img-fluid for-light"
      :src="require('@/assets/images/b2b/logo-white-mode.svg')"
      alt=""
    /><img
      class="img-fluid for-dark"
      :src="require('@/assets/images/b2b/logo-dark-mode.svg')"
      alt=""
    />
  </router-link>
</template>

<script>
  export default {
    name: 'Logo',
  };
</script>
<style scoped>

img{
  width: 11.5rem;
  margin-left: -14px;
}

</style>
