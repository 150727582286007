<template>
  <div id="ModalAgencyDetails" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Agency Details</h4>
          <button
              class="btn-close"
              type="button"
              aria-label="Close"
              @click="closeAgencyDetailsModal"
          ></button>
        </div>
        <div id="ModalBodyAgencyDetails" class="modal-body">
          <div class="col-sm-12 col-xl-12">
            <div class="card">
              <div class="card-header p-0 pb-2">
                <div class="row invo-profile">
                  <div class="col-xl-6">
                    <div class="invo-profile-left">
                      <div class="d-flex">
                        <div class="d-flex-left">
                          <img
                              class="d-flex-object rounded-circle img-60"
                              :src="logoUrl"
                              alt=""
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h5 class="d-flex-heading">
                            {{ the_agency?.title }}
                          </h5>
                          <p>
                            {{ the_agency?.email }}
                            <br/>
                            <span class="digits">
                              {{
                                (the_agency?.customized?.country_code ?? '') +
                                ' ' +
                                (the_agency?.customized?.phone ?? '')
                              }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="invo-profile-right">
                      <div id="project" class="text-xl-end">
                        <p class="m-0">
                          {{ the_agency?.country?.name }} /
                          {{ the_agency?.state?.name }} /
                          {{ the_agency?.city?.name }}
                        </p>
                        <p class="m-0">{{ the_agency?.customized?.address }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="icon-tab" role="tablist">
                  <li class="nav-item">
                    <a
                        class="nav-link active"
                        id="icon-home-tab"
                        data-bs-toggle="tab"
                        href="#agency-details-general"
                        role="tab"
                        aria-controls="agency-details-general"
                        aria-selected="true"
                    >
                      <i class="icofont icofont-ui-home"></i>
                      {{ $translate('agencies.agency_detail.tabs.general') }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                        class="nav-link"
                        id="profile-icon-tab"
                        data-bs-toggle="tab"
                        href="#agency-details-users"
                        role="tab"
                        aria-controls="agency-details-users"
                        aria-selected="false"
                    >
                      <i class="icofont icofont-man-in-glasses"></i>
                      {{ $translate('agencies.agency_detail.tabs.users') }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                        class="nav-link"
                        id="contact-icon-tab"
                        data-bs-toggle="tab"
                        href="#agency-details-settings"
                        role="tab"
                        aria-controls="agency-details-settings"
                        aria-selected="false"
                    >
                      <i class="fa fa-cogs"></i>
                      {{ $translate('agencies.agency_detail.tabs.settings') }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                        class="nav-link"
                        id="contact-icon-tab"
                        data-bs-toggle="tab"
                        href="#agency-details-permissions"
                        role="tab"
                        aria-controls="agency-details-settings"
                        aria-selected="false"
                    >
                      <i class="fa fa-cogs"></i>
                      Permissions
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                        class="nav-link"
                        id="contact-icon-tab"
                        data-bs-toggle="tab"
                        href="#agency-details-markups"
                        role="tab"
                        aria-controls="agency-details-markups"
                        aria-selected="false"
                    >
                      <i class="fa fa-money"></i>
                      Markups
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                        class="nav-link"
                        id="contact-icon-tab"
                        data-bs-toggle="tab"
                        href="#agency-details-wallets"
                        role="tab"
                        aria-controls="agency-details-markups"
                        aria-selected="false"
                    >
                      <i class="icofont icofont-wallet"></i>
                      Wallet
                    </a>
                  </li>
                </ul>
                <div class="tab-content p-2" id="icon-tabContent">
                  <div
                      class="tab-pane fade show active"
                      id="agency-details-general"
                      role="tabpanel"
                      aria-labelledby="icon-home-tab"
                  >
                    <General :agency="the_agency"/>
                  </div>
                  <div
                      class="tab-pane fade"
                      id="agency-details-users"
                      role="tabpanel"
                      aria-labelledby="profile-icon-tab"
                  >
                    user tab
                  </div>
                  <div
                      class="tab-pane fade"
                      id="agency-details-settings"
                      role="tabpanel"
                      aria-labelledby="contact-icon-tab"
                  >
                    settings tab
                  </div>
                  <div
                      class="tab-pane fade"
                      id="agency-details-permissions"
                      role="tabpanel"
                      aria-labelledby="contact-icon-tab"
                  >
                    <AgencyPermissions/>
                  </div>
                  <div
                      class="tab-pane fade"
                      id="agency-details-markups"
                      role="tabpanel"
                      aria-labelledby="contact-icon-tab"
                  >

                    <ul class="nav nav-tabs" id="icon-tab" role="tablist">
                      <li class="nav-item">
                        <a
                            class="nav-link active"
                            id="icon-home-tab"
                            data-bs-toggle="tab"
                            href="#agency-details-current-markups"
                            role="tab"
                            aria-controls="agency-details-current-markups"
                            aria-selected="true"
                        >
                          <i class="fa fa-percent"></i>
                          Current Markups
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                            class="nav-link"
                            id="profile-icon-tab"
                            data-bs-toggle="tab"
                            href="#agency-details-edit-markups"
                            role="tab"
                            aria-controls="agency-details-edit-markups"
                            aria-selected="false"
                        >
                          <i class="fa fa-edit"></i>
                          Edit Markups
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content p-2" id="icon-tabContent">
                      <div
                          class="tab-pane fade  show active"
                          id="agency-details-current-markups"
                          role="tabpanel"
                          aria-labelledby="icon-home-tab"
                      >
                        <CurrentMarkups/>
                      </div>
                      <div
                          class="tab-pane fade"
                          id="agency-details-edit-markups"
                          role="tabpanel"
                          aria-labelledby="profile-icon-tab"
                      >
                        <EditSelfMarkups
                            @refresh-agency-detail="getAgencyDetailsToModal(agencyStore.selected_agency.id)"/>
                      </div>
                    </div>


                  </div>
                  <div
                      class="tab-pane fade"
                      id="agency-details-wallets"
                      role="tabpanel"
                      aria-labelledby="contact-icon-tab"
                  >
                    <AgencyWallets ref="agencyWalletsRef"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-secondary"
              @click="closeAgencyDetailsModal"
          >
            {{ $translate('common.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import General from '@/components/Agency/Details/includes/General.vue';
import {computed, onMounted, ref} from 'vue';
import {Modal} from 'bootstrap';
import {BlockContainer, UnBlocker} from '@/core/services/general.service';
import {useAgencyStore} from '@/stores/agencyStore';
import {$translate} from '@/core/services/translator.service';
import AgencyPermissions from "@/components/Agency/Details/includes/AgencyPermissions.vue";
import AgencyMarkups from "@/components/Agency/Details/includes/CurrentMarkups.vue";
import CurrentMarkups from "@/components/Agency/Details/includes/CurrentMarkups.vue";
import EditSelfMarkups from "@/components/Agency/Details/includes/EditSelfMarkups.vue";
import AgencyWallets from "@/components/Agency/Details/includes/Wallets/AgencyWallets.vue";

const agencyWalletsRef = ref(null);

const emit = defineEmits(['close-modal']);

const logoUrl =
    process.env.VUE_APP_API_URL + '/images/logo/grandoria-ico.jpg';

const agencyStore = useAgencyStore();
const the_agency = computed(() => agencyStore.selected_agency);

const getAgencyDetailsToModal = async (agency_id) => {
  // console.log('Clicked ID:', id);
  openAgencyDetailsModal();

  const loader = BlockContainer('ModalBodyAgencyDetails');
  await agencyStore.getAgencyDetails(agency_id);

  agencyWalletsRef.value.refreshDataGrid();

  UnBlocker(loader);
};

const modalAgencyDetails = ref(null);

onMounted(() => {
  modalAgencyDetails.value = new Modal(
      document.getElementById('ModalAgencyDetails'),
      {
        backdrop: false,
        keyboard: true,
        focus: true,
        show: false,
      }
  );
});

const openAgencyDetailsModal = () => {
  modalAgencyDetails.value.show();
};

const closeAgencyDetailsModal = () => {
  modalAgencyDetails.value.hide();
};

const childFunction = () => {
  // console.log('child component called!');
};

defineExpose({
  getAgencyDetailsToModal,
});
</script>

<style scoped>
ul.nav-tabs li.nav-item {
  list-style: none;
}
</style>
