<template>
  <div class="container-fluid">
    <Breadcrumbs main="Acenteler"/>

    <div class="row starter-main">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header p-3">
            <h5>Acente Yönetimi (Diyagram)</h5>

            <h6 class="text-primary">Base Price :
              <PriceView :price="basePrice"/>
            </h6>

            <div class="card-header-right d-flex align-items-center justify-content-end p-2">

              <div style="position: absolute; width: 12rem; right: 63px; bottom: -28px;" class="dx-field">
                <div class="dx-field-value w-100">
                  <DxSelectBox
                      :label-mode="'floating'"
                      label="Displayed Markup"
                      :items="agencyStore.diagram.packages"
                      :value="agencyStore.diagram.displayed_markup_package_id"
                      @value-changed="handlePackageChange"

                      value-expr="id"
                      display-expr="name"
                      :input-attr="{ 'aria-label': 'Simple Product' }"
                  />
                </div>
              </div>

              <DropDownToolBox :items="diagramToolBoxItems"/>

            </div>
          </div>

          <div style="min-height: 100vh;" class="card-body">
            <div id="container">
              <DxDiagram
                  id="diagram"
                  ref="diagram"
                  page-orientation="landscape"
                  :simple-view="true"
                  :context-menu="{enabled:false}"
                  :show-grid="false"
              >
                <DxContextMenu
                    :enabled="true"
                    :commands="['bringToFront', 'sendToBack' , 'lock' , 'unlock' , 'exportPng', 'exportJpg', 'simpleView', 'fullScreen']"
                />

                <DxCustomShape
                    v-for="agency in agencies"
                    :key="agency.id"
                    :type="'employee' + agency.id"
                    :base-type="'rectangle'"
                    :default-width="2.5"
                    :default-height="1.85"
                    :allow-edit-text="false"
                    :allow-resize="false"
                />
                <DxNodes
                    :data-source="dataSource"
                    :key-expr="'id'"
                    :type-expr="itemTypeExpr"
                    :parent-key-expr="'parent_agency_id'"
                    :text-expr="'Full_Name'"
                >
                  <DxAutoLayout :type="'tree'"/>
                </DxNodes>
                <template #customShapeTemplate="{ data }">
                  <CustomShapeTemplate
                      :agency="data.dataItem"
                      :show-info="showInfo"
                  />
                </template>

                <DxToolbox :visibility="'disabled'"/>
              </DxDiagram>

              <AgencyDetailGm ref="agencyDetailsGm"/>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalCalculateTestMarkup ref="modalCalculateTestMarkup" @apply-base-price="handleBasePrice"/>

</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue';
import {
  DxDiagram, DxNodes, DxAutoLayout, DxCustomShape, DxToolbox, DxContextMenu
} from 'devextreme-vue/diagram';

import DxSelectBox from "devextreme-vue/select-box";
import ArrayStore from 'devextreme/data/array_store';
import CustomShapeTemplate from './CustomShapeTemplate.vue';
import {useAgencyStore} from "@/stores/agencyStore";
import {BlockContainer, currencyExchange, UnBlocker} from "@/core/services/general.service";
import AgencyDetailGm from '@/components/Agency/Details/AgencyDetailGm.vue';
import DropDownToolBox from "@/components/DropDownToolBox.vue";
import PriceView from "@/components/PriceView.vue";
import ModalCalculateTestMarkup from "@/pages/agencies/diagram/ModalCalculateTestMarkup.vue";
import {useAuthStore} from "@/stores/authStore";
import {ucfirst} from "@/core/services/string.service";


const basePrice = computed(() => agencyStore.diagram.base_price)

const applyMarkup = (agency, baseDomesticPrice, baseInternationalPrice, basePrice = null) => {
  let domesticMarkup = baseDomesticPrice;
  let internationalMarkup = baseInternationalPrice;
  let generalMarkup = basePrice;

  const updateMarkup = (markup, basePrice, updatedPrice) => {
    if (markup.category.name === "percent") {
      return updatedPrice + (updatedPrice * markup.value / 100);
    } else if (markup.category.name === "per-transaction") {
      return updatedPrice + currencyExchange(markup.value, markup.currency).converted_price;
    }
    return basePrice; // Default to base price if no specific category matched
  };

  agency.markups.forEach((markup) => {
    if (markup.package_id === agencyStore.diagram.displayed_markup_package_id) {
      if (markup.has_permission) {
        if (markup.package_type?.name === "domestic") {
          domesticMarkup = updateMarkup(markup, baseDomesticPrice, domesticMarkup);
          markup.calculated_markup = domesticMarkup;
        } else if (markup.package_type?.name === "international") {
          internationalMarkup = updateMarkup(markup, baseInternationalPrice, internationalMarkup);
          markup.calculated_markup = internationalMarkup;
        } else {
          generalMarkup = updateMarkup(markup, basePrice, generalMarkup);
          markup.calculated_markup = generalMarkup;
        }
      } else {
        // If no permission, revert to the base price
        if (!markup.package_type) {
          markup.calculated_markup = basePrice;
        } else {
          markup.calculated_markup = markup.package_type.name === "domestic"
              ? baseDomesticPrice
              : baseInternationalPrice;
        }
      }
    }
  });

  // Propagate the updated prices to sub-agencies
  agencies.value.forEach((subAgency) => {
    if (subAgency.parent_agency_id === agency.id) {
      applyMarkup(subAgency, domesticMarkup, internationalMarkup, generalMarkup);
    }
  });
};


const authStore = useAuthStore();


const handleBasePrice = (price) => {
  // console.log("here is the handle base price", price)
  agencyStore.diagram.base_price = price;


  if (!agencies?.value?.length) return;

  agencies.value.forEach((agency) => {
    if (!agency.parent_agency_id) {
      applyMarkup(agency, price, price, price);  // Initialize both domestic and international with the base price
    }
  });

  // console.log("Updated agencies", agencies.value);

  const loader = BlockContainer('container');

  dataSource.value = new ArrayStore({
    key: 'id',
    data: agencies.value,
  });

  UnBlocker(loader);
};


watch(() => authStore.currentCurrency, (newCurrency, oldCurrency) => {
  // console.log(`Currency changed from ${oldCurrency} to ${newCurrency}`);
  handleBasePrice(agencyStore.diagram.base_price);
});


const modalCalculateTestMarkup = ref(null);

const diagramToolBoxItems = [
  {
    label: 'Save',
    callback: async () => await saveChanges()
  },
  {
    label: 'Refresh Diagram',
    callback: async () => await loadData()
  },
  {
    label: 'Calculate Test Markup',
    callback: async () => modalCalculateTestMarkup.value.openModal()
  },
];

const agencyDetailsGm = ref(null);
const diagram = ref(null);

const agencyStore = useAgencyStore();
const agencies = ref([]);
const edges = ref([]);

const dataSource = ref(new ArrayStore({
  key: 'id',
  data: agencies.value,
}));
const itemTypeExpr = ({id}) => `employee${id}`;

function showInfo(employee) {
  agencyDetailsGm.value.getAgencyDetailsToModal(employee.id);
}

const saveChanges = async () => {
  const loader = BlockContainer('container');
  await agencyStore.saveAgencyParentRelationship(agencies.value);

  await loadData();


  UnBlocker(loader);
}


const loadData = async () => {
  const loader = BlockContainer('container');
  const diagramData = await agencyStore.getDiagramData();

  agencies.value = diagramData.agencies;
  agencyStore.diagram.packages = diagramData.packages;

  if (!agencyStore?.diagram?.packages?.length) return;

  agencyStore.diagram.packages.forEach(packageVal => {
    packageVal.name = ucfirst(packageVal.name);
  })
  dataSource.value = new ArrayStore({
    key: 'id',
    data: agencies.value,
  });


  UnBlocker(loader);


  if (agencyStore.diagram.base_price !== 0)
    handleBasePrice(agencyStore.diagram.base_price);
}


onMounted(async () => {
  await loadData();

  if (agencyStore.diagram.base_price !== 0)
    handleBasePrice(agencyStore.diagram.base_price);


});


watch(agencies, (newAgencies) => {
  dataSource.value = new ArrayStore({
    key: 'id',
    data: newAgencies,
  });
});

const handlePackageChange = async (e) => {
  // console.log("handle package e ", e);

  agencyStore.diagram.displayed_markup_package_id = e.value;

  diagram.value.instance.repaint();

  handleBasePrice(agencyStore.diagram.base_price)

};

</script>

<style scoped>
#diagram {
  height: 725px;
}

.dx-popup-content p {
  margin: 0 0 10px;
}
</style>
