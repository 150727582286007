import {defineStore} from "pinia";
import {systemConfigurationService} from "@/core/api/system-configuration.api";
import {BlockContainer, UnBlocker} from "@/core/services/general.service";
import {errorService} from "@/core/services/error.service";

export const useModuleSortingStore = defineStore('moduleSortingStore', {
    state: () => ({
        moduleGroups: [],
        currentPage: null,
        modules: [],
        subModules: [],
        allModuleData: [],
    }),
    getters: {},
    actions: {
        async getModuleGroups() {
            const loader = BlockContainer('top-tabContent');
            try {
                const response = await systemConfigurationService.getModuleGroups();
                response.data.module_groups.sort((a, b) => a.order - b.order);
                this.moduleGroups = response.data.module_groups;
            } catch (e) {
                console.error('Error fetching module groups:', e);
                errorService.apiClientError(e);
            } finally {
                UnBlocker(loader);
            }
        },
        async saveOrderModuleGroups(orderData) {
            try {
                return await systemConfigurationService.saveOrderModuleGroups(orderData);
            } catch (e) {
                errorService.apiClientError(e);
                console.error('Error at saveOrderModuleGroups:', e);
            }
        },

        async getModules(moduleGroupId) {
            const loader = BlockContainer('top-tabContent');
            try {
                const response = await systemConfigurationService.getModulesOfModuleGroup(moduleGroupId);
                this.modules = response.data.modules;
            } catch (e) {
                errorService.apiClientError(e);
                console.error('Error at getModules:', e);
            } finally {
                UnBlocker(loader);
            }
        },

        async saveOrderModules(orderData) {
            try {
                return await systemConfigurationService.saveOrderModules(orderData);
            } catch (e) {
                errorService.apiClientError(e);
                console.error('Error at saveOrderModules:', e);
            }
        },

        async getSubModulesAndOthers() {
            const loader = BlockContainer('dxSortSubModule');
            try {
                const response = await systemConfigurationService.getSubModulesAndOthers();
                this.allModuleData = response.data.all_data;
            } catch (e) {
                errorService.apiClientError(e);
                console.error('Error at getSubModulesAndOthers:', e);
            } finally {
                UnBlocker(loader);
            }
        },


        async saveOrderSubModules(orderData) {
            const loader = BlockContainer('top-tabContent');
            try {
                return await systemConfigurationService.saveOrderSubModules(orderData);
            } catch (e) {
                errorService.apiClientError(e);
                console.error('Error at saveOrderSubModules:', e);
            } finally {
                UnBlocker(loader);
            }
        },

    }
});
