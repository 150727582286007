<template>

  <DxDataGrid
      :show-borders="true"
      :data-source="drpStore.sub_module_tree"
      :show-row-lines="true"
      :show-column-lines="true"
      :remote-operations="false"
      :hover-state-enabled="true"
      :allow-column-resizing="true"
      @selection-changed="onSelectionChanged"
      :column-resizing-mode="'widget'"
      :width="'100%'"
      style="zoom: .75"
      :height="700"
      :no-data-text="'Alt modül bulunamadı!'"
  >
    <DxSelection mode="single"/>


    <DxColumn width="250" data-field="name" caption="Alt Modül" cell-template="subModuleNameTemplate"/>
    <template #subModuleNameTemplate="{data:rowInfo}">
      <b :class="rowInfo.data.role_has_permissions_count ? 'text-success' : 'text-danger'">{{ rowInfo.data.name }}</b>
    </template>


    <DxColumn data-field="description" caption="Açıklama" cell-template="descriptionTemplate"></DxColumn>
    <template #descriptionTemplate="{ data: rowInfo }">
      <div class="flex justify-center">
        <p>
          <b class="text-primary">{{ rowInfo.data.permissions.length }}</b>/<b
            class="text-success">{{ rowInfo.data.role_has_permissions_count }}</b> İzin</p>
      </div>
    </template>


    <DxSearchPanel
        :visible="true"
        :highlight-case-sensitive="true"
    />
  </DxDataGrid>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxRequiredRule,
  DxSelection,
  DxPager,
} from 'devextreme-vue/data-grid';
import {
  dxPageSizes,
} from "@/core/services/devextreme.datagrid.service";
import {useDefaultRolePermissionStore} from "@/stores/SystemConfiguration/defaultRolePermissionStore";
import {ref} from "vue";

const drpStore = useDefaultRolePermissionStore();
const dataSource = ref(drpStore.module_tree);


const onSelectionChanged = ({selectedRowsData}) => {
  const data = selectedRowsData[0];

  if (data?.id !== undefined) {
    drpStore.selected_sub_module = data?.id;
    drpStore.getPermissionTree(data?.id);
  }
};

</script>


<style scoped>
.container-select-module {
  cursor: pointer;
}
</style>