import {defineStore} from "pinia";
import {systemConfigurationService} from "@/core/api/system-configuration.api";
import {ToastMessage} from "@/core/services/general.service";
import {errorService} from "@/core/services/error.service";

export const useModuleStore = defineStore('moduleStore', {
    state: () => ({
        moduleGroups: [],
        moduleLocations: [
            {id: 1, name: 'sidebar', display_name: 'SideBar'},
            {id: 2, name: 'other', display_name: 'Other'},
        ],
        noSubModuleTypes: [
            {id: 1, value: 0, display_name: 'Hayır'},
            {id: 2, value: 1, display_name: 'Evet'},
        ],
        editModuleDetails: null,
        editMode: false,
    }),
    getters: {
        getEditModuleData(state) {
            if (state.editMode && state.editModuleDetails) {
                return {
                    ...state.editModuleDetails,
                    module_group: state.editModuleDetails.module_group_id,
                    path: state.editModuleDetails.url,
                    no_sub_module: state.editModuleDetails.no_sub_module === 1,
                };
            }
            return null;
        }
    },
    actions: {

        async getModuleGroups() {
            try {
                const response = await systemConfigurationService.getModuleGroups();
                this.moduleGroups = response.data.module_groups;
            } catch (e) {
                errorService.apiClientError(e);
            }
        },

        async createModule(formData) {
            try {
                return await systemConfigurationService.createModule(formData);
            } catch (e) {
                errorService.apiClientError(e);
            }
        },
        async editModule(formData) {
            try {
                return await systemConfigurationService.editModule(formData);
            } catch (e) {
                errorService.apiClientError(e);
            }
        },
        setEditMode(editMode, moduleDetails = null) {
            this.editMode = editMode;
            this.editModuleDetails = moduleDetails;
        }
    }
});