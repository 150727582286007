<template>
  <div style="overflow-y: auto" class="customizer-header">
    <i style="z-index: 99999" class="icofont-close icon-close" @click="closeCustomized()"></i>
    <h5>Quick Options</h5>

    <div class="mt-5" id="global-sidebar-area"></div>


    <div class="row mt-4">

      <div style="zoom: .85;" class="col-md-12">
        <div style="background-image: url('https://vue.pixelstrap.com/cuba/img/profile-bg.2d0f7fce.png')"
             class="card social-profile">
          <div class="card-body">
            <div class="social-img-wrap">
              <div class="social-img">
                <!--                <img src="@/assets/images/dashboard-5/profile.png" alt="profile"/>-->
                <img :src="authStore.user?.avatar" alt="profile"/>
              </div>
              <div class="edit-icon">
                <svg>
                  <use href="@/assets/svg/icon-sprite.svg#profile-check"></use>
                </svg>
              </div>
            </div>
            <div class="social-details">
              <h5 class="mb-1">
                <a style="text-transform: none;" href="javascript:void(0)">{{ authStore.agency?.title }}</a>
              </h5>
              <span class="f-light">#{{ authStore.agency?.agency_code }}</span>
            </div>
          </div>
        </div>
      </div>


      <div v-for="wallet in wallets" :key="wallet.id" class="col-sm-12">
        <WalletWidget :wallet="wallet"/>
      </div>
    </div>

  </div>
</template>

<script setup>
import {useAuthStore} from "@/stores/authStore";
import {useStore} from "vuex";
import {computed} from "vue";
import {priceFormatter} from "@/core/services/general.service";
import WalletWidget from "@/components/WalletWidget.vue";

const authStore = useAuthStore();
const store = useStore();


const wallets = computed(() => authStore.agency?.wallets);


const closeCustomized = () => {
  store.state.menu.customizer = '';
}
</script>


<style scoped>
.customizer-header {
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 5rem !important;
}

.customizer-header::-webkit-scrollbar {
  width: 8px;
}

.customizer-header::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.customizer-header::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 10px;
}

.customizer-header::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3;
}
</style>

