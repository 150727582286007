<template>
  <div>
    <DxDataGrid
        :show-borders="true"
        :data-source="agencyStore.selected_agency?.current_markups?.markups"
        :column-auto-width="true"
        :show-row-lines="true"
        theme="compact"
        :show-column-lines="true"
        :row-alternation-enabled="true"
        :remote-operations="false"
        :allow-column-resizing="true"
        :column-resizing-mode="'widget'"
        :width="'100%'"
        :height="500"
        @toolbarPreparing="dxOnToolbarPreparing"
        @contextMenuPreparing="dxOnContextMenuPreparing"
        @exporting="(e) => dxOnExcelExporting(e, 'Agency Permission', 'Agency Permission')"
    >
      <DxExport :enabled="true" :allow-export-selected-data="false"/>
      <DxPaging :page-size="500"/>


      <DxColumn data-field="package_type.package.name" caption="Package Name"/>
      <DxColumn data-field="package_type.name" caption="Pakcage Type"/>
      <DxColumn data-field="category.name" caption="Markup Type"/>
      <DxColumn data-field="value" caption="Markup" cell-template="markupTemplate" />
      <template #markupTemplate="{data:rowInfo}">
        <b v-if="rowInfo.data.category.name === 'percent'" class="text-primary">%{{ rowInfo.data.value }}</b>
        <b v-else class="text-dark">{{ priceFormatter(rowInfo.data.value, rowInfo.data.currency) }}</b>
      </template>
      <DxColumn data-field="has_permission" caption="Permission Allowed"/>
      <DxColumn data-field="source" caption="Source"/>


      <DxFilterRow :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxGroupPanel visible="true"/>
      <DxSearchPanel :visible="true" :highlight-case-sensitive="true"/>
    </DxDataGrid>
  </div>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxSelection,
  DxGroupPanel,
  DxRequiredRule,
  DxPaging,
  DxPager,
  DxExport,
} from "devextreme-vue/data-grid";
import {
  dxOnExcelExporting,
  dxOnContextMenuPreparing,
  dxPageSizes,
  dxOnToolbarPreparing,
  dxDateEditorOptions,
  dxOnBeforeSendFunction,
  dxOnAjaxErrorHandler,
} from "@/core/services/devextreme.datagrid.service";
import {useAgencyStore} from "@/stores/agencyStore";
import {createStore} from "devextreme-aspnet-data-nojquery";
import {priceFormatter} from "@/core/services/general.service";

const agencyStore = useAgencyStore();

</script>

<style lang="scss" scoped></style>
