<template>
  <div id="ModalModelStatus" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="modalLabel">Change Status</h4>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <Form
              ref="veeForm"
              as="form"
              id="formStatus"
              @submit="handleSubmit"
              v-slot="{ errors }"
          >
            <div class="row mb-2">
              <div class="col-md-12">
                <label class="form-label">
                  Status
                </label>
                <Field
                    v-model="status_form_data.status"
                    name="status"
                    type="text"
                    as="select"
                    class="form-control"
                    placeholder="Status"
                    rules="required"
                    :class="{ 'is-invalid': errors.status }"
                >
                  <option value="1">Active</option>
                  <option value="0">Passive</option>
                </Field>
                <ErrorMessage as="div" class="invalid-feedback" name="status"/>
              </div>
              <div class="col-md-12 mt-2">
                <label class="form-label">
                  Status Description
                </label>
                <Field
                    v-model="status_form_data.status_description"
                    name="status_description"
                    type="text"
                    as="textarea"
                    class="form-control"
                    placeholder="Status Description"
                    rules=""
                    :class="{ 'is-invalid': errors.status_description }"
                />
                <small class="text-primary">The default description the user will see:
                  <b>"{{ default_description_message }}"</b></small>
                <ErrorMessage as="div" class="invalid-feedback" name="status_description"/>
              </div>
            </div>
          </Form>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" form="formStatus">
            {{ $translate('common.save_changes') }}
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">
            {{ $translate('common.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import {ref, onMounted, watch} from 'vue';
import {Modal} from 'bootstrap';
import {$translate} from '@/core/services/translator.service';
import {ErrorMessage, Field, Form} from 'vee-validate';

const modalModelStatus = ref(null);
const veeForm = ref(null);


const emit = defineEmits(['save-status-data']);

const props = defineProps({
  default_description_message: {
    type: String,
    required: true,
  }
});

const status_form_data = ref({
  status: null,
  status_description: '',
});


onMounted(() => {
  modalModelStatus.value = new Modal(document.getElementById('ModalModelStatus'), {
    backdrop: 'static',
    keyboard: true,
  });
});

const openModal = (status, statusDescription) => {
  status_form_data.value.status = status;
  status_form_data.value.status_description = statusDescription;
  modalModelStatus.value.show();
};

const closeModal = () => {
  modalModelStatus.value.hide();
};

const handleSubmit = () => {
  emit('save-status-data', status_form_data.value)
};

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped>
</style>