<template>
  <div>
    <select ref="select2" :name="name" :class="[{ 'is-invalid': meta.error }, 'form-control']" :id="id">
      <option value="">{{ placeholder }}</option>
      <option v-for="option in options" :key="option.id" :value="option.id">{{ option.name }}</option>
    </select>
    <ErrorMessage :name="name" class="invalid-feedback"/>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { useField, ErrorMessage } from "vee-validate";
import $ from "jquery";
import "select2/dist/css/select2.css";

const props = defineProps({
  dropDownParent: {
    type: String,
    default: false
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Select an option',
  },
  rules: {
    type: String,
    default: 'required',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
});

const emit = defineEmits([
  'change',
  'select',
  'update:modelValue'
]);

const { value: fieldValue, meta, setTouched } = useField(props.name, props.rules);
const select2 = ref(null);

const formatOption = (option) => {
  if (!option.id) {
    return option.text;
  }
  const country = props.options.find(opt => opt.id == option.id);
  if (country) {
    return $(
        `<span><i class="flag-icon flag-icon-${country.iso2.toLowerCase()}"></i> ${country.name} (${country.phonecode})</span>`
    );
  }
  return option.text;
};

const formatSelection = (option) => {
  if (!option.id) {
    return option.text;
  }
  const country = props.options.find(opt => opt.id == option.id);
  if (country) {
    return $(
        `<span><i class="flag-icon flag-icon-${country.iso2.toLowerCase()}"></i> <span class="flag-text"> (${country.phonecode})</span></span>`
    );
  }
  return option.text;
};

const matcher = (params, data) => {
  if ($.trim(params.term) === '') {
    return data;
  }

  if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
    return data;
  }

  const country = props.options.find(opt => opt.id == data.id);
  if (country && country.phonecode.toString().indexOf(params.term) > -1) {
    return data;
  }

  return null;
};



onMounted(() => {

  $(select2.value).select2({
    dropdownParent: props.dropDownParent ? $(props.dropDownParent) : null,
    templateResult: formatOption,
    templateSelection: formatSelection,
    matcher: matcher,
  });

  $(select2.value).on('change', (e) => {
    fieldValue.value = e.target.value;
    emit('change', e.target.value);
    emit('update:modelValue', e.target.value);
  });

  $(select2.value).on('select2:select', (e) => {
    emit('select', { id: e.params.data.id, text: e.params.data.text });
  });

  $(select2.value).on('select2:close', () => {
    setTouched();
  });
});

onBeforeUnmount(() => {
  $(select2.value).off('change select2:select');
  $(select2.value).select2('destroy');
});

watch(fieldValue, (newValue) => {
  if ($(select2.value).val() !== newValue) {
    $(select2.value).val(newValue).trigger('change');
  }
});

watch(() => props.modelValue, (newValue) => {
  if ($(select2.value).val() !== newValue) {
    $(select2.value).val(newValue).trigger('change');
  }
});


</script>

<style scoped>
.invalid-feedback {
  display: block;
}
</style>
