import {defineStore} from "pinia";
import {errorService} from "@/core/services/error.service";
import {userService} from "@/core/api/user.api";

export const useUserStore = defineStore('userStore', {
    state: () => ({
        roleGroups: [],
        selected_user: {},
        available_roles: [],
    }),
    getters: {},
    actions: {
        /**
         * Retrieves the details of the user with the specified user ID.
         *
         * @param {number} user_id
         *
         * @return {Promise<boolean>} Whether the operation was successful
         * */
        async getUserDetails(user_id) {
            return await userService.getUserDetails(user_id)
                // .then(response => response.data.agency)
                .then(response => {
                    this.selected_user = response.data.user;
                    return true;
                })
                .catch(error => {
                    console.log(error)
                    errorService.apiClientError(error);
                    return false;
                });
        },


        /**
         * When registering a new user, it pulls the available authorisations that the user can assign.
         *
         * @method POST
         *
         * @return {Promise<void>} List of available roles
         * */
        async getPossibleRoles() {
            await userService.getPossibleRoles()
                .then((response) => {
                    this.available_roles = response.data.available_roles;
                })
                .catch(error => {
                    errorService.apiClientError(error);
                    this.available_roles = [];
                })
        },

        /**
         * Creates the user.
         *
         * @method POST
         *
         * @return {Promise}
         * */
        async createUser(user_data) {
            return await userService.createUser(user_data);
        },


        /**
         * Updates the user.
         *
         * @method PUT
         *
         * @return {Promise}
         * */
        async updateUser(user_data) {
            return await userService.updateUser(user_data);
        },

        /**
         * Deletes the user.
         *
         * @method DELETE
         *
         * @return {Promise}
         * */
        async deleteUser(id) {
            return await userService.deleteUser(id);
        },


        /**
         * Repair the deleted user.
         *
         * @method POST
         *
         * @return {Promise}
         * */
        async rollbackUser(id) {
            return await userService.rollbackUser(id);
        },

        /**
         * Reset the user password.
         *
         * @method POST
         *
         * @return {Promise}
         * */
        async resetPassword(user_id) {
            return await userService.resetPassword(user_id);
        },

        /**
         * Change the user status.
         *
         * @method POST
         *
         * @return {Promise}
         * */
        async changeStatus(status_data) {
            return await userService.changeStatus(status_data);
        },

    }
});