<template>
  <div class="container-fluid">
    <Breadcrumbs :main="'Translation List'" :breadcrumbItems="breadcrumbItems" />

    <div class="row starter-main">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>
              <b class="text-primary">{{ module_name }}</b>
              Translation List/Module Details
            </h5>
            <div class="card-header-right">
              <select v-model="selectedLanguage" @change="onLanguageChange" class="form-control">
                <option value="en">EN</option>
                <option value="tr">TR</option>
                <option value="es">ES</option>
                <option value="ru">RU</option>
                <option value="ar">AR</option>
              </select>
            </div>
          </div>
          <div class="card-body">
            <DxDataGrid
              id="translationDxGrid"
              ref="dataGridRef"
              :show-borders="true"
              :data-source="dataSource"
              :show-row-lines="true"
              :show-column-lines="true"
              :row-alternation-enabled="true"
              :remote-operations="false"
              :column-auto-width="true"
              :repaint-changes-only="true"
              :allow-column-resizing="true"
              :column-resizing-mode="'widget'"
              :width="'100%'"
              :height="700"
              @toolbarPreparing="dxOnToolbarPreparing"
              @contextMenuPreparing="(e) => dxOnContextMenuPreparing(e, customContexts)"
              @exporting="(e) => dxOnExcelExporting(e, `${module_name} Translation Details`, 'Translation List')"
              :key-expr="'id'"
            >
              <DxKeyboardNavigation
                :edit-on-key-press="editOnKeyPress"
                :enter-key-action="enterKeyAction"
                :enter-key-direction="enterKeyDirection"
              />

              <DxExport :enabled="true" :allow-export-selected-data="false" />
              <DxPaging :page-size="500" />
              <DxPager
                :visible="true"
                :allowed-page-sizes="dxPageSizes"
                :display-mode="'compact'"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
              />

              <DxColumn :allow-editing="false" :visible="false" data-field="id" caption="id" />

              <DxColumn :allow-editing="false" :group-index="0" data-field="key" caption="Key" />
              <DxColumn :allow-editing="false" data-field="source_text" caption="Source Text" />
              <DxColumn :allow-editing="false" data-field="default_translation" caption="Default Translation" />
              <DxColumn data-field="translation" caption="Translation" />

              <DxEditing
                :allow-adding="true"
                :allow-updating="true"
                :allow-deleting="true"
                mode="batch"
                :select-text-on-edit-start="true"
              />

              <DxColumn type="buttons">
                <DxButton icon="edit" @click="(e) => modalKeyEditFormRef.openModal(e, true)" />
                <DxButton name="delete" @click="(e) => deleteTranslation(e)" />
                <DxButton name="cancelRowChanges" />
              </DxColumn>

              <DxFilterRow :visible="true" />
              <DxHeaderFilter :visible="true" />

              <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
              <DxColumnChooser :enabled="true" />

              <DxGroupPanel :visible="true" />
              <DxGrouping :auto-expand-all="false" />

              <DxToolbar>
                <DxItem location="after">
                  <button @click="modalCreateKeyFormRef.openModal()" class="btn btn-primary btn-xs">
                    <i class="fa fa-plus"></i>
                  </button>
                </DxItem>
                <DxItem name="exportButton" />
                <DxItem name="createButton" />
                <DxItem
                  widget="dxButton"
                  location="after"
                  toolbar="bottom"
                  :options="{ text: 'rollback', onClick: cancelChanges }"
                />

                <DxItem name="groupPanel" />

                <DxItem
                  widget="dxButton"
                  location="after"
                  toolbar="bottom"
                  :options="{ text: 'Save', onClick: saveChanges }"
                />
                <DxItem name="searchPanel" />
              </DxToolbar>

              <DxSummary>
                <DxGroupItem column="key" summary-type="count" display-format="{0} word" />
              </DxSummary>
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalKeyEditForm ref="modalKeyEditFormRef" :module-name="module_name" @save-translation="saveTranslationFromModal" />

  <ModalCreateTranslationKeyForm
    ref="modalCreateKeyFormRef"
    :module-name="module_name"
    @refreshDataGrid="dataGridRef.instance.refresh()"
  />
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxPaging,
  DxPager,
  DxExport,
  DxButton,
  DxToolbar,
  DxKeyboardNavigation,
  DxItem,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
  DxSummary,
  DxGroupItem,
  DxTotalItem,
} from "devextreme-vue/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { translationService } from "@/core/api/translation.api";
import {
  dxOnExcelExporting,
  dxOnContextMenuPreparing,
  dxPageSizes,
  dxOnToolbarPreparing,
  dxOnBeforeSendFunction,
  dxOnAjaxErrorHandler,
} from "@/core/services/devextreme.datagrid.service";

import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import ModalKeyEditForm from "@/pages/translation/module_detail/modals/ModalKeyEditForm.vue";
import ModalCreateTranslationKeyForm from "@/pages/translation/module_detail/modals/ModalCreateTranslationKeyForm.vue";

import { useTranslationStore } from "@/stores/translationStore";
import { BlockContainer, ToastMessage, UnBlocker } from "@/core/services/general.service";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";

const authStore = useAuthStore();

const customContexts = [
  {
    text: "Update System Language",
    icon: "download",
    onItemClick: () => {
      authStore.refreshForLangVersion(true);
    },
  },
];

const router = useRouter();

const translationStore = useTranslationStore();

const editOnKeyPress = ref(true);
const enterKeyDirection = ref("column");
const enterKeyAction = ref("moveFocus");

const modalKeyEditFormRef = ref(null);
const modalCreateKeyFormRef = ref(null);

const dataGridRef = ref();
const selectedLanguage = ref();
const module_name = ref();
const route = useRoute();

const createDataSource = () => {
  const loadUrl = translationService.moduleDetailsLoad(module_name.value) + "?selectLanguage=" + selectedLanguage.value;
  const updateOrCreateUrl = translationService.moduleDetailsUpdateOrCreate(module_name.value);
  return createStore({
    key: "id",
    loadUrl: loadUrl,
    insertUrl: `${loadUrl}/insert`,
    updateUrl: updateOrCreateUrl,
    deleteUrl: `${loadUrl}/delete`,
    onBeforeSend: dxOnBeforeSendFunction,
    onAjaxError: dxOnAjaxErrorHandler,
  });
};

const dataSource = ref();

onMounted(() => {
  module_name.value = route.params.module_name;
  selectedLanguage.value = route.params.selected_language;
  dataSource.value = createDataSource();

  console.log(selectedLanguage.value);
});

const breadcrumbItems = [
  {
    name: "TranslationList",
    path: "/Translation/TranslationList",
    active: false,
  },
  {
    name: module_name,
    path: "/Translation/TranslationList",
    active: false,
  },
  {
    name: "ModuleDetails",
    active: true,
  },
];

const onLanguageChange = () => {
  router.push({
    name: "translation_module_detail",
    params: { module_name: module_name.value, selected_language: selectedLanguage.value },
  });
};

watch(selectedLanguage, onLanguageChange);

const saveTranslationFromModal = (e) => {
  saveEdit(e.id, e.translation);
};

const deleteTranslation = (e) => {
  let confirmResult = confirm("Are you sure you want to delete this translation?");
  if (confirmResult) saveEdit(e.row.data.id, null);
};

const saveEdit = (idParam, translationParam) => {
  const dataGrid = dataGridRef.value.instance;
  const dataSource = dataGrid.getDataSource();
  const data = dataSource.items();

  const itemIndex = data.findIndex((d) => d.id === idParam);
  if (itemIndex !== -1) {
    // Batch edit mode kullanarak hücre güncellemesi yap
    dataGrid.cellValue(itemIndex, "translation", translationParam);
    dataGrid.repaintRows([itemIndex]); // Satırı yeniden boyamak için
  } else {
    saveEditWithGroup(idParam, translationParam);
  }
};

const saveEditWithGroup = (idParam, translationParam) => {
  const dataGrid = dataGridRef.value.instance;
  const dataSource = dataGrid.getDataSource();
  const data = dataSource.items();
  let found = false;

  const updateTranslation = (items, parentIndex = 0) => {
    let currentIndex = parentIndex;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.items) {
        const result = updateTranslation(item.items, currentIndex + 1);
        if (result.found) {
          return result;
        }
        currentIndex = result.currentIndex;
      } else if (item.id === idParam) {
        dataGrid.cellValue(currentIndex + 1, "translation", translationParam);
        dataGrid.repaintRows([currentIndex + 1]); // Satırı yeniden boyamak için
        return { found: true, currentIndex };
      }
      currentIndex++;
    }
    return { found, currentIndex };
  };

  if (Array.isArray(data) && data.length && data[0].hasOwnProperty("items")) {
    let rowIndex = 0;
    for (const group of data) {
      if (group.items) {
        const result = updateTranslation(group.items, rowIndex);
        if (result.found) {
          found = true;
          break;
        }
        rowIndex = result.currentIndex;
      }
      rowIndex++;
    }

    if (!found) {
      rowIndex = 0;
      for (const group of data) {
        if (group.collapsedItems) {
          const result = updateTranslation(group.collapsedItems, rowIndex);
          if (result.found) {
            found = true;
            break;
          }
          rowIndex = result.currentIndex;
        }
        rowIndex++;
      }
    }
  }

  if (!found) {
    alert("Item with the given ID not found.");
  }
};

// Functions for the manually added buttons
const cancelChanges = () => {
  let cResult = confirm("Your changes will be reverted, do you want to continue?");
  if (cResult) {
    dataGridRef.value.instance.cancelEditData(); // Tüm değişiklikleri iptal et
    dataGridRef.value.instance.refresh(); // Grid'i yenile
  }
};

const saveChanges = async () => {
  const dataGrid = dataGridRef.value.instance;
  const changes = dataGrid.option("editing.changes");

  /* Data Manupulation */

  let ChnagesArray = [];
  changes.map((val) => {
    ChnagesArray.push({
      originalkey: val.key,
      newvalue: val.data.translation,
      vardatatype: "string",
    });
  });

  const PostData = {
    data: ChnagesArray,
    fileName: module_name.value,
    language: selectedLanguage.value,
  };


  let loader = BlockContainer("translationDxGrid");

  const response = await translationStore.updateTranslations(module_name.value, PostData);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  if (response) {
    dataGridRef.value.instance.cancelEditData();
    dataGridRef.value.instance.refresh();
    ToastMessage("success", "Translation saved successfully!", "Successfully!");
  } else ToastMessage("error", "An error ocurred!", "Error");

  UnBlocker(loader);
};
</script>

<style scoped>
/* Add necessary styles */
</style>
