import CryptoJS from "crypto-js";

const LaravelEncrypt = function () {
    this.key = CryptoJS.enc.Base64.parse(process.env.VUE_APP_LANG_KEY);
};

LaravelEncrypt.prototype.decrypt = function (value) {
    value = unsanitizeCharacters(value);
    try{
        const bytes = CryptoJS.AES.decrypt(value, this.key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (e){
        console.error("error came up on encryption!");
    }
};

/* Yalnızca VUE tarafından oluşturulmuş şifrelemeyi çözer*/
LaravelEncrypt.prototype.decrypt_vue = function (encryptStr) {
    try {
        let key = CryptoJS.enc.Base64.parse(this.key);

        let ivBase64 = encryptStr.substring(0, 24);
        let encryptedBase64 = encryptStr.substring(24);

        let iv = CryptoJS.enc.Base64.parse(ivBase64);
        let encrypted = CryptoJS.enc.Base64.parse(encryptedBase64);

        let decrypted = CryptoJS.AES.decrypt(
            { ciphertext: encrypted },
            key,
            { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
        );

        decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
        return decrypted;
    } catch (error) {
        return null;
    }
};

/* Yalnızca LARAVEL tarafından oluşturulmuş şifrelemeyi çözer*/
LaravelEncrypt.prototype.encrypt = function (value) {
    let encrypted_string =  CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), this.key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).toString();

    return sanitizeCharacters(encrypted_string);
};


function sanitizeCharacters(filename) {
    return filename.replace(/[\/\\+=:*?"<>|]/g, function(match) {
        switch (match) {
            case '/': return '_SLASH_';
            case '\\': return '_BACKSLASH_';
            case '+': return '_PLUS_';
            case '=': return '';
            case ':': return '_COLON_';
            case '*': return '_ASTERISK_';
            case '?': return '_QUESTION_';
            case '"': return '_QUOTE_';
            case '<': return '_LESS_';
            case '>': return '_GREATER_';
            case '|': return '_PIPE_';
        }
    });
}

function unsanitizeCharacters(filename) {
    return filename.replace(/_(SLASH|BACKSLASH|PLUS|EQUAL|COLON|ASTERISK|QUESTION|QUOTE|LESS|GREATER|PIPE)_/g, function(match) {
        switch (match) {
            case '_SLASH_': return '/';
            case '_BACKSLASH_': return '\\';
            case '_PLUS_': return '+';
            case '_EQUAL_': return '';
            case '_COLON_': return ':';
            case '_ASTERISK_': return '*';
            case '_QUESTION_': return '?';
            case '_QUOTE_': return '"';
            case '_LESS_': return '<';
            case '_GREATER_': return '>';
            case '_PIPE_': return '|';
        }
    });
}

export default LaravelEncrypt;